const meta = {
  'metrics-traces': 'Metrics and traces',
  'third-party': 'Change events',
  logs: 'Logs',
  'logs-oem': {
    display: 'hidden',
  },
};

export default meta;
