const meta = {
  'aws-eventbridge': 'AWS EventBridge',
  buildkite: 'Buildkite',
  circleci: 'CircleCI',
  github: 'GitHub',
  gitlab: 'GitLab',
  'google-cloud': 'Google Cloud',
  harness: 'Harness',
  launchdarkly: 'LaunchDarkly',
};

export default meta;
