const meta = {
  index: 'Chronosphere Docs',
  'chronosphere-separator': {
    type: 'separator',
    title: 'OBSERVABILITY PLATFORM',
  },
  overview: 'Overview',
  navigate: 'Navigate',
  observe: 'Observe',
  investigate: 'Investigate',
  control: 'Control',
  alerts: 'Alerting',
  administer: 'Administer',
  ingest: 'Ingest',
  'calyptia-separator': {
    type: 'separator',
    title: 'TELEMETRY PIPELINE',
  },
  pipelines: 'Overview',
  'pipeline-navigate': 'Navigate',
  'pipeline-install': 'Install',
  'pipeline-configure': 'Configure',
  'pipeline-data': 'Data',
  fleets: 'Fleets',
  'pipeline-administer': 'Administer',
  'developer-separator': {
    type: 'separator',
    title: 'TOOLS',
  },
  'tooling-overview': 'Overview',
  tooling: 'Observability Platform',
  'pipeline-cli': 'Pipeline CLI',
  'info-separator': {
    type: 'separator',
    title: 'MORE INFORMATION',
  },
  support: 'Support',
  status: 'Service status',
  compliance: 'Compliance',
  'platform-link': {
    title: 'Products',
    type: 'page',
    href: 'https://chronosphere.io/platform/',
  },
  'solutions-link': {
    title: 'Solutions',
    type: 'page',
    href: 'https://chronosphere.io/solutions/',
  },
  'company-link': {
    title: 'Company',
    type: 'page',
    href: 'https://chronosphere.io/about/',
  },
  'resources-link': {
    title: 'Resources',
    type: 'page',
    href: 'https://chronosphere.io/resources/',
  },
  'contact-link': {
    title: 'Contact',
    type: 'page',
    href: 'https://chronosphere.io/contact/',
  },
  404: {
    type: 'page',
  },
};

export default meta;
