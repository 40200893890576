const meta = {
  'accounts-teams': 'Accounts and teams',
  collections: 'Collections',
  integrations: 'Integrations',
  'audit-logs': 'Audit logs',
  'pinned-scopes': {
    display: 'hidden',
  },
  'limits-licensing': 'Licensing and limits',
  'service-discovery': 'Lens configuration',
};

export default meta;
