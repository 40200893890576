const meta = {
  install: 'Install',
  configure: 'Configure',
  discover: 'Discover',
  monitor: 'Verify and monitor',
  mappings: 'Mappings',
  'addl-metrics': 'Additional metrics',
  debugging: 'Debug endpoints',
};

export default meta;
