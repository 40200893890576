const meta = {
  'json-representation': {
    display: 'hidden',
  },
  'bar-chart': 'Bar chart',
  'gauge-chart': 'Gauge',
  'heat-map': 'Heat map',
  markdown: 'Markdown',
  'pie-chart': 'Pie chart',
  'stat-chart': 'Stat chart',
  table: 'Table',
  'time-series-chart': 'Time series',
};

export default meta;
