const meta = {
  'automated-logging': 'Automated Kubernetes logging',
  'create-modify': 'Create and modify pipelines',
  parsers: 'Parsers',
  plugins: 'Plugins',
  'processing-rules': 'Processing rules',
  troubleshoot: 'Troubleshooting',
};

export default meta;
