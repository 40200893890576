const meta = {
  alerting: {
    display: 'hidden',
  },
  dashboards: {
    display: 'hidden',
  },
  'external-tasks': {
    display: 'hidden',
  },
  'key-concepts': {
    display: 'hidden',
  },
  'logscale-configuration': {
    display: 'hidden',
  },
};

export default meta;
