const meta = {
  types: 'Metric types',
  rules: 'Reduce stored metrics',
  'aggregation-rules-ui': 'Aggregation rules UI',
  'shaping-impact': 'Shaping impact preview',
  quotas: 'Metrics quotas',
  downsampling: 'Long-term downsampling',
  'reduce-cardinality': 'Reduce cardinality',
};

export default meta;
