const meta = {
  pagination: 'Pagination',
  endpoint_config: {
    title: 'Config endpoint reference',
    href: '/tooling/api-info/definition',
    newWindow: true,
  },
  endpoint_state: {
    title: 'State endpoint reference',
    href: '/tooling/api-info/state_definition',
    newWindow: true,
  },
  endpoint_data: {
    title: 'Data endpoint reference',
    href: '/tooling/api-info/data_definition',
    newWindow: true,
  },
};

export default meta;
