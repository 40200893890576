const meta = {
  'gcp-logs': 'Google Cloud Platform',
  'logstash-logs': 'Logstash',
  'otel-logs': 'OpenTelemetry',
  'pipeline-logs': 'Pipeline',
  'verify-logs': 'Verify logs',
  recommended: {
    display: 'hidden',
  },
  existing: {
    display: 'hidden',
  },
  'otel-logs-oem': {
    display: 'hidden',
  },
};

export default meta;
