const meta = {
  wavefront: 'Wavefront',
  signalfx: 'SignalFx',
  dogstatsd: 'DogStatsD',
  statsd: 'StatsD',
  carbon: 'Carbon',
  'prom-pushgateway': 'Prometheus Pushgateway',
  'prom-openmetrics': 'Prometheus and OpenMetrics',
};

export default meta;
