const meta = {
  'query-logs': 'Search logs',
  metrics: 'Search metrics',
  traces: 'Search traces',
  'dashboard-query': 'Dashboard queries',
  'create-links': 'Create links',
  'regular-expressions': 'Regular expressions',
  'glob-syntax': 'Glob syntax',
  logs: {
    display: 'hidden',
  },
};

export default meta;
