const meta = {
  prereqs: 'Prerequisites',
  'install-operator': 'Install a Core Operator',
  'create-instance': 'Create a Core Instance',
  'environment-variables': 'Linux environment variables',
  troubleshooting: 'Linux troubleshooting',
  upgrade: 'Upgrade',
  uninstall: 'Uninstall',
};

export default meta;
