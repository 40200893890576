const meta = {
  discard: 'Discard',
  email: 'Email',
  incident: 'incident.io',
  opsgenie: 'Opsgenie',
  pagerduty: 'PagerDuty',
  slack: 'Slack',
  victorops: 'VictorOps',
  webhook: 'Webhook',
};

export default meta;
