import meta from "../../../pages/_meta.ts";
import administer_meta from "../../../pages/administer/_meta.ts";
import administer_accounts_teams_meta from "../../../pages/administer/accounts-teams/_meta.ts";
import administer_collections_meta from "../../../pages/administer/collections/_meta.ts";
import administer_integrations_meta from "../../../pages/administer/integrations/_meta.ts";
import administer_limits_licensing_meta from "../../../pages/administer/limits-licensing/_meta.ts";
import administer_limits_licensing_limits_meta from "../../../pages/administer/limits-licensing/limits/_meta.ts";
import alerts_meta from "../../../pages/alerts/_meta.ts";
import alerts_monitors_meta from "../../../pages/alerts/monitors/_meta.ts";
import alerts_notifications_meta from "../../../pages/alerts/notifications/_meta.ts";
import alerts_notifications_notifiers_meta from "../../../pages/alerts/notifications/notifiers/_meta.ts";
import control_meta from "../../../pages/control/_meta.ts";
import control_budgets_meta from "../../../pages/control/budgets/_meta.ts";
import control_sampling_meta from "../../../pages/control/sampling/_meta.ts";
import control_shaping_meta from "../../../pages/control/shaping/_meta.ts";
import control_shaping_quotas_meta from "../../../pages/control/shaping/quotas/_meta.ts";
import control_shaping_rules_meta from "../../../pages/control/shaping/rules/_meta.ts";
import fleets_meta from "../../../pages/fleets/_meta.ts";
import ingest_meta from "../../../pages/ingest/_meta.ts";
import ingest_logs_meta from "../../../pages/ingest/logs/_meta.ts";
import ingest_metrics_traces_meta from "../../../pages/ingest/metrics-traces/_meta.ts";
import ingest_metrics_traces_collector_meta from "../../../pages/ingest/metrics-traces/collector/_meta.ts";
import ingest_metrics_traces_collector_addl_metrics_meta from "../../../pages/ingest/metrics-traces/collector/addl-metrics/_meta.ts";
import ingest_metrics_traces_collector_configure_meta from "../../../pages/ingest/metrics-traces/collector/configure/_meta.ts";
import ingest_metrics_traces_collector_discover_meta from "../../../pages/ingest/metrics-traces/collector/discover/_meta.ts";
import ingest_metrics_traces_collector_install_meta from "../../../pages/ingest/metrics-traces/collector/install/_meta.ts";
import ingest_metrics_traces_collector_mappings_meta from "../../../pages/ingest/metrics-traces/collector/mappings/_meta.ts";
import ingest_metrics_traces_collector_mappings_datadog_meta from "../../../pages/ingest/metrics-traces/collector/mappings/datadog/_meta.ts";
import ingest_metrics_traces_collector_mappings_prometheus_meta from "../../../pages/ingest/metrics-traces/collector/mappings/prometheus/_meta.ts";
import ingest_metrics_traces_collector_monitor_meta from "../../../pages/ingest/metrics-traces/collector/monitor/_meta.ts";
import ingest_metrics_traces_otel_meta from "../../../pages/ingest/metrics-traces/otel/_meta.ts";
import ingest_third_party_meta from "../../../pages/ingest/third-party/_meta.ts";
import investigate_meta from "../../../pages/investigate/_meta.ts";
import investigate_change_events_meta from "../../../pages/investigate/change-events/_meta.ts";
import investigate_change_events_enable_events_meta from "../../../pages/investigate/change-events/enable-events/_meta.ts";
import investigate_differential_diagnosis_meta from "../../../pages/investigate/differential-diagnosis/_meta.ts";
import investigate_logs_meta from "../../../pages/investigate/logs/_meta.ts";
import investigate_logs_alerting_meta from "../../../pages/investigate/logs/alerting/_meta.ts";
import investigate_metrics_meta from "../../../pages/investigate/metrics/_meta.ts";
import investigate_querying_meta from "../../../pages/investigate/querying/_meta.ts";
import investigate_querying_metrics_meta from "../../../pages/investigate/querying/metrics/_meta.ts";
import investigate_querying_query_logs_meta from "../../../pages/investigate/querying/query-logs/_meta.ts";
import investigate_traces_meta from "../../../pages/investigate/traces/_meta.ts";
import investigate_traces_explorer_meta from "../../../pages/investigate/traces/explorer/_meta.ts";
import navigate_meta from "../../../pages/navigate/_meta.ts";
import observe_meta from "../../../pages/observe/_meta.ts";
import observe_dashboards_meta from "../../../pages/observe/dashboards/_meta.ts";
import observe_dashboards_classic_dashboards_meta from "../../../pages/observe/dashboards/classic-dashboards/_meta.ts";
import observe_dashboards_customization_meta from "../../../pages/observe/dashboards/customization/_meta.ts";
import observe_dashboards_panels_meta from "../../../pages/observe/dashboards/panels/_meta.ts";
import observe_services_meta from "../../../pages/observe/services/_meta.ts";
import observe_slo_meta from "../../../pages/observe/slo/_meta.ts";
import overview_meta from "../../../pages/overview/_meta.ts";
import pipeline_administer_meta from "../../../pages/pipeline-administer/_meta.ts";
import pipeline_cli_meta from "../../../pages/pipeline-cli/_meta.ts";
import pipeline_configure_meta from "../../../pages/pipeline-configure/_meta.ts";
import pipeline_data_meta from "../../../pages/pipeline-data/_meta.ts";
import pipeline_data_plugins_meta from "../../../pages/pipeline-data/plugins/_meta.ts";
import pipeline_data_plugins_destination_plugins_meta from "../../../pages/pipeline-data/plugins/destination-plugins/_meta.ts";
import pipeline_data_plugins_source_plugins_meta from "../../../pages/pipeline-data/plugins/source-plugins/_meta.ts";
import pipeline_data_processing_rules_meta from "../../../pages/pipeline-data/processing-rules/_meta.ts";
import pipeline_install_meta from "../../../pages/pipeline-install/_meta.ts";
import pipeline_install_install_operator_meta from "../../../pages/pipeline-install/install-operator/_meta.ts";
import pipelines_meta from "../../../pages/pipelines/_meta.ts";
import tooling_meta from "../../../pages/tooling/_meta.ts";
import tooling_api_info_meta from "../../../pages/tooling/api-info/_meta.ts";
import tooling_chronoctl_meta from "../../../pages/tooling/chronoctl/_meta.ts";
import tooling_infrastructure_meta from "../../../pages/tooling/infrastructure/_meta.ts";
import tooling_infrastructure_terraform_meta from "../../../pages/tooling/infrastructure/terraform/_meta.ts";
export const pageMap = [{
  data: meta
}, {
  name: "404",
  route: "/404",
  frontMatter: {
    "sidebarTitle": "404"
  }
}, {
  name: "administer",
  route: "/administer",
  children: [{
    data: administer_meta
  }, {
    name: "accounts-teams",
    route: "/administer/accounts-teams",
    children: [{
      data: administer_accounts_teams_meta
    }, {
      name: "okta",
      route: "/administer/accounts-teams/okta",
      frontMatter: {
        "title": "Okta user synchronization",
        "description": "Configure user synchronization between Okta and Chronosphere Observability Platform."
      }
    }, {
      name: "personal-access-tokens",
      route: "/administer/accounts-teams/personal-access-tokens",
      frontMatter: {
        "title": "Personal access tokens",
        "description": "How Chronosphere Observability Platform supplies users more secure access to its APIs, Chronoctl, and Terraform."
      }
    }, {
      name: "service-accounts",
      route: "/administer/accounts-teams/service-accounts",
      frontMatter: {
        "title": "Service accounts",
        "description": "An explanation of Chronosphere service accounts and usage."
      }
    }, {
      name: "teams",
      route: "/administer/accounts-teams/teams",
      frontMatter: {
        "title": "Teams",
        "description": "Chronosphere Observability Platform lets you create and manage teams, assign roles, and set permissions for effective collaboration."
      }
    }, {
      name: "user-accounts",
      route: "/administer/accounts-teams/user-accounts",
      frontMatter: {
        "title": "User accounts",
        "description": "Chronosphere Observability Platform lets you manage user access by handling account creation, modification, and role assignments."
      }
    }]
  }, {
    name: "accounts-teams",
    route: "/administer/accounts-teams",
    frontMatter: {
      "title": "Accounts and teams",
      "description": "Chronosphere Observability Platform lets you manage user accounts, roles, and team permissions for streamlined access and collaboration."
    }
  }, {
    name: "audit-logs",
    route: "/administer/audit-logs",
    frontMatter: {
      "title": "Audit logs"
    }
  }, {
    name: "collections",
    route: "/administer/collections",
    children: [{
      data: administer_collections_meta
    }, {
      name: "home",
      route: "/administer/collections/home",
      frontMatter: {
        "title": "Team and collection home pages"
      }
    }, {
      name: "migration",
      route: "/administer/collections/migration",
      frontMatter: {
        "title": "Migrate from buckets to collections"
      }
    }]
  }, {
    name: "collections",
    route: "/administer/collections",
    frontMatter: {
      "title": "Collections"
    }
  }, {
    name: "integrations",
    route: "/administer/integrations",
    children: [{
      data: administer_integrations_meta
    }, {
      name: "slack",
      route: "/administer/integrations/slack",
      frontMatter: {
        "title": "Slack unfurling integration",
        "description": "Learn how to unfurl monitor graphs in Slack."
      }
    }]
  }, {
    name: "integrations",
    route: "/administer/integrations",
    frontMatter: {
      "title": "Chronosphere Observability Platform integrations"
    }
  }, {
    name: "limits-licensing",
    route: "/administer/limits-licensing",
    children: [{
      data: administer_limits_licensing_meta
    }, {
      name: "licensing",
      route: "/administer/limits-licensing/licensing",
      frontMatter: {
        "title": "Observability Platform licensing",
        "description": "Learn about licensing concepts and retention periods in Chronosphere Observability Platform."
      }
    }, {
      name: "limits",
      route: "/administer/limits-licensing/limits",
      children: [{
        data: administer_limits_licensing_limits_meta
      }, {
        name: "event-limits",
        route: "/administer/limits-licensing/limits/event-limits",
        frontMatter: {
          "title": "Change event limits"
        }
      }, {
        name: "metric-limits",
        route: "/administer/limits-licensing/limits/metric-limits",
        frontMatter: {
          "title": "Metric limits"
        }
      }, {
        name: "query-limits",
        route: "/administer/limits-licensing/limits/query-limits",
        frontMatter: {
          "title": "Query limits"
        }
      }, {
        name: "trace-limits",
        route: "/administer/limits-licensing/limits/trace-limits",
        frontMatter: {
          "title": "Tracing ingest limits"
        }
      }]
    }, {
      name: "limits",
      route: "/administer/limits-licensing/limits",
      frontMatter: {
        "title": "Observability Platform system limits",
        "description": "Learn about system limits in Chronosphere Observability Platform."
      }
    }]
  }, {
    name: "limits-licensing",
    route: "/administer/limits-licensing",
    frontMatter: {
      "title": "Licensing and system limits",
      "description": "Learn about licensing and system limits and how they differ."
    }
  }, {
    name: "pinned-scopes",
    route: "/administer/pinned-scopes",
    frontMatter: {
      "title": "Pinned scopes"
    }
  }, {
    name: "service-discovery",
    route: "/administer/service-discovery",
    frontMatter: {
      "title": "Managing Chronosphere Lens service configuration",
      "description": "Discover and configure Chronosphere Lens services in Chronosphere Observability Platform"
    }
  }]
}, {
  name: "administer",
  route: "/administer",
  frontMatter: {
    "title": "Administer your tools and users",
    "description": "Chronosphere Observability Platform offers tools and guidance for managing users, teams, collections, and your infrastructure-as-code settings."
  }
}, {
  name: "alerts",
  route: "/alerts",
  children: [{
    data: alerts_meta
  }, {
    name: "metrics",
    route: "/alerts/metrics",
    frontMatter: {
      "title": "Querying metrics about alerts"
    }
  }, {
    name: "monitors",
    route: "/alerts/monitors",
    children: [{
      data: alerts_monitors_meta
    }, {
      name: "data-model",
      route: "/alerts/monitors/data-model",
      frontMatter: {
        "title": "Monitor data model",
        "description": "Learn about the data model for monitors."
      }
    }, {
      name: "monitor-details",
      route: "/alerts/monitors/monitor-details",
      frontMatter: {
        "title": "Monitor details",
        "description": "Learn about the details of Chronosphere monitors."
      }
    }]
  }, {
    name: "monitors",
    route: "/alerts/monitors",
    frontMatter: {
      "title": "Create monitors to generate alerts and notifications",
      "description": "View and create monitors for alerting."
    }
  }, {
    name: "muting-rules",
    route: "/alerts/muting-rules",
    frontMatter: {
      "title": "Setting monitor muting rules",
      "description": "View and create muting rules for monitors."
    }
  }, {
    name: "notifications",
    route: "/alerts/notifications",
    children: [{
      data: alerts_notifications_meta
    }, {
      name: "notifiers",
      route: "/alerts/notifications/notifiers",
      children: [{
        data: alerts_notifications_notifiers_meta
      }, {
        name: "discard",
        route: "/alerts/notifications/notifiers/discard",
        frontMatter: {
          "title": "Create a discard notifier",
          "description": "Create a discard notifier with Chronoctl or Terraform."
        }
      }, {
        name: "email",
        route: "/alerts/notifications/notifiers/email",
        frontMatter: {
          "title": "Create an email notifier",
          "description": "Create an email notifier with Chronosphere Observability Platform, Chronoctl, or Terraform."
        }
      }, {
        name: "incident",
        route: "/alerts/notifications/notifiers/incident",
        frontMatter: {
          "title": "Create an incident.io notifier",
          "description": "Create an incident.io notifier with Chronosphere Observability Platform."
        }
      }, {
        name: "opsgenie",
        route: "/alerts/notifications/notifiers/opsgenie",
        frontMatter: {
          "title": "Create an Opsgenie notifier",
          "description": "Create an Opsgenie notifier with Chronosphere Observability Platform, Chronoctl, or Terraform."
        }
      }, {
        name: "pagerduty",
        route: "/alerts/notifications/notifiers/pagerduty",
        frontMatter: {
          "title": "Create a PagerDuty notifier",
          "description": "Create a PagerDuty notifier with Chronosphere Observability Platform, Chronoctl, or Terraform."
        }
      }, {
        name: "slack",
        route: "/alerts/notifications/notifiers/slack",
        frontMatter: {
          "title": "Create a Slack notifier",
          "description": "Create a Slack notifier with Chronosphere Observability Platform, Chronoctl, or Terraform."
        }
      }, {
        name: "victorops",
        route: "/alerts/notifications/notifiers/victorops",
        frontMatter: {
          "title": "Create a VictorOps notifier",
          "description": "Create a VictorOps notifier with Chronosphere Observability Platform, Chronoctl, or Terraform."
        }
      }, {
        name: "webhook",
        route: "/alerts/notifications/notifiers/webhook",
        frontMatter: {
          "title": "Create a generic webhook notifier",
          "description": "Create a generic webhook notifier with Chronosphere Observability Platform, Chronoctl, or Terraform."
        }
      }]
    }, {
      name: "notifiers",
      route: "/alerts/notifications/notifiers",
      frontMatter: {
        "title": "Notifiers",
        "description": "View and create notifiers with Chronosphere Observability Platform, Chronoctl, or Terraform."
      }
    }, {
      name: "policies",
      route: "/alerts/notifications/policies",
      frontMatter: {
        "title": "Notification policies",
        "description": "View and create notification policies with Chronosphere Observability Platform, Chronoctl, or Terraform."
      }
    }, {
      name: "signals",
      route: "/alerts/notifications/signals",
      frontMatter: {
        "title": "Signals",
        "description": "View and create signals with Chronosphere Observability Platform."
      }
    }]
  }, {
    name: "notifications",
    route: "/alerts/notifications",
    frontMatter: {
      "title": "Alert notifications",
      "description": "Learn about notifiers and notification policies."
    }
  }, {
    name: "troubleshooting",
    route: "/alerts/troubleshooting",
    frontMatter: {
      "title": "Troubleshooting monitors and alerts",
      "description": "Learn about troubleshooting monitors and alerts in Chronosphere Observability Platform."
    }
  }]
}, {
  name: "alerts",
  route: "/alerts",
  frontMatter: {
    "title": "Overview of alerting",
    "description": "Chronosphere Observability Platform lets you create and manage custom alerts for timely notifications about critical issues."
  }
}, {
  name: "compliance",
  route: "/compliance",
  frontMatter: {
    "title": "Chronosphere compliance information",
    "description": "Information about Chronosphere compliance standards"
  }
}, {
  name: "control",
  route: "/control",
  children: [{
    data: control_meta
  }, {
    name: "budgets",
    route: "/control/budgets",
    children: [{
      data: control_budgets_meta
    }, {
      name: "budgeting",
      route: "/control/budgets/budgeting",
      frontMatter: {
        "title": "Log dataset budgets",
        "description": "Learn how to manage budgets to allocate a percentage of your log license limit to datasets."
      }
    }, {
      name: "datasets",
      route: "/control/budgets/datasets",
      frontMatter: {
        "title": "Log datasets",
        "description": "Use datasets in Chronosphere Observability Platform to map sets of logs to named groups relevant to your organization."
      }
    }]
  }, {
    name: "budgets",
    route: "/control/budgets",
    frontMatter: {
      "title": "Control log data",
      "description": "Learn how to create budgets for your log data in Observability Platform and control the data you keep."
    }
  }, {
    name: "sampling",
    route: "/control/sampling",
    children: [{
      data: control_sampling_meta
    }, {
      name: "behaviors",
      route: "/control/sampling/behaviors",
      frontMatter: {
        "title": "Use trace behaviors with datasets",
        "description": "Use behaviors in Chronosphere Observability Platform to set sampling rules for your trace datasets without writing fine-grained sampling rules."
      }
    }, {
      name: "datasets",
      route: "/control/sampling/datasets",
      frontMatter: {
        "title": "Trace datasets",
        "description": "Use datasets in Chronosphere Observability Platform to map sets of traces to named groups relevant to your organization."
      }
    }, {
      name: "head-sampling",
      route: "/control/sampling/head-sampling",
      frontMatter: {
        "title": "Head sampling"
      }
    }, {
      name: "tail-sampling",
      route: "/control/sampling/tail-sampling",
      frontMatter: {
        "title": "Tail sampling"
      }
    }]
  }, {
    name: "sampling",
    route: "/control/sampling",
    frontMatter: {
      "title": "Sample your traces",
      "description": "Use sampling in Chronosphere Observability Platform to help control costs and maximize the usefulness of your tracing data."
    }
  }, {
    name: "shaping",
    route: "/control/shaping",
    children: [{
      data: control_shaping_meta
    }, {
      name: "aggregation-rules-ui",
      route: "/control/shaping/aggregation-rules-ui",
      frontMatter: {
        "title": "Aggregation rules UI"
      }
    }, {
      name: "downsampling",
      route: "/control/shaping/downsampling",
      frontMatter: {
        "title": "Long-term downsampling"
      }
    }, {
      name: "quotas",
      route: "/control/shaping/quotas",
      children: [{
        data: control_shaping_quotas_meta
      }, {
        name: "manage-pools",
        route: "/control/shaping/quotas/manage-pools",
        frontMatter: {
          "title": "Manage metric pools"
        }
      }, {
        name: "quotas-ui",
        route: "/control/shaping/quotas/quotas-ui",
        frontMatter: {
          "title": "View allocated pools"
        }
      }]
    }, {
      name: "quotas",
      route: "/control/shaping/quotas",
      frontMatter: {
        "title": "Understand metric quotas and pools",
        "description": "Learn about metric quotas and pools, and how to give each pool a specific quota of your total persisted writes license."
      }
    }, {
      name: "reduce-cardinality",
      route: "/control/shaping/reduce-cardinality",
      frontMatter: {
        "title": "Reduce cardinality",
        "description": "Learn how to reduce metric cardinality."
      }
    }, {
      name: "rules",
      route: "/control/shaping/rules",
      children: [{
        data: control_shaping_rules_meta
      }, {
        name: "drop-rules",
        route: "/control/shaping/rules/drop-rules",
        frontMatter: {
          "title": "Drop rules"
        }
      }, {
        name: "evaluation-failures",
        route: "/control/shaping/rules/evaluation-failures",
        frontMatter: {
          "title": "Find and fix rule and monitor evaluation failures"
        }
      }, {
        name: "mapping",
        route: "/control/shaping/rules/mapping",
        frontMatter: {
          "title": "Mapping rules"
        }
      }, {
        name: "recording",
        route: "/control/shaping/rules/recording",
        frontMatter: {
          "title": "Recording rules"
        }
      }, {
        name: "rollup",
        route: "/control/shaping/rules/rollup",
        frontMatter: {
          "title": "Rollup rules",
          "description": "What are rollup rules, and how to we create, edit, or delete them."
        }
      }]
    }, {
      name: "rules",
      route: "/control/shaping/rules",
      frontMatter: {
        "title": "Reduce metric data",
        "description": "Learn about the ways to reduce metric data storage in Chronosphere Observability Platform."
      }
    }, {
      name: "shaping-impact",
      route: "/control/shaping/shaping-impact",
      frontMatter: {
        "title": "Shaping impact preview"
      }
    }, {
      name: "types",
      route: "/control/shaping/types",
      frontMatter: {
        "title": "Metric types"
      }
    }]
  }, {
    name: "shaping",
    route: "/control/shaping",
    frontMatter: {
      "title": "Shape your metrics data"
    }
  }, {
    name: "storage",
    route: "/control/storage",
    frontMatter: {
      "title": "Data control concepts",
      "description": "Learn about data control concepts in Observability Platform and how you can use them to control your data."
    }
  }]
}, {
  name: "control",
  route: "/control",
  frontMatter: {
    "title": "Control your telemetry data",
    "description": "Learn about how to control your telemetry data with Chronosphere Observability Platform."
  }
}, {
  name: "fleets",
  route: "/fleets",
  children: [{
    data: fleets_meta
  }, {
    name: "add-agent-to-fleet",
    route: "/fleets/add-agent-to-fleet",
    frontMatter: {
      "title": "Add agents to a fleet",
      "description": "Learn how to add agents to a fleet in Chronosphere Telemetry Pipeline."
    }
  }, {
    name: "add-files-to-fleet",
    route: "/fleets/add-files-to-fleet",
    frontMatter: {
      "title": "Add files to a fleet",
      "description": "Learn how to add files to a fleet."
    }
  }, {
    name: "agent",
    route: "/fleets/agent",
    frontMatter: {
      "title": "Agent types",
      "description": "Learn about the types of agents compatible with Chronosphere Telemetry Pipeline."
    }
  }]
}, {
  name: "fleets",
  route: "/fleets",
  frontMatter: {
    "title": "Fleets",
    "description": "Learn about fleet management in Chronosphere Telemetry Pipeline."
  }
}, {
  name: "index",
  route: "/",
  frontMatter: {
    "title": "Chronosphere documentation",
    "description": "Learn about the various aspects of the Chronosphere documentation."
  }
}, {
  name: "ingest",
  route: "/ingest",
  children: [{
    data: ingest_meta
  }, {
    name: "logs",
    route: "/ingest/logs",
    children: [{
      data: ingest_logs_meta
    }, {
      name: "existing",
      route: "/ingest/logs/existing",
      frontMatter: {
        "title": "Existing ingest configuration",
        "description": "Use your existing ingest configuration to ingest logs into Chronosphere Observability Platform.",
        "robots": "noindex, nofollow"
      }
    }, {
      name: "gcp-logs",
      route: "/ingest/logs/gcp-logs",
      frontMatter: {
        "title": "Route logs from Google Cloud Platform",
        "description": "Learn how to route logs from Google Cloud Platform into Chronosphere Observability Platform."
      }
    }, {
      name: "logstash-logs",
      route: "/ingest/logs/logstash-logs",
      frontMatter: {
        "title": "Route logs from Logstash",
        "description": "Learn how to route logs from Logstash to Chronosphere Observability Platform."
      }
    }, {
      name: "otel-logs-oem",
      route: "/ingest/logs/otel-logs-oem",
      frontMatter: {
        "title": "Ingest logs using the OpenTelemetry Collector",
        "description": "Use the OpenTelemetry Collector to ingest logs to Chronosphere Observability Platform.",
        "robots": "noindex, nofollow"
      }
    }, {
      name: "otel-logs",
      route: "/ingest/logs/otel-logs",
      frontMatter: {
        "title": "Use OpenTelemetry protocol endpoints",
        "description": "Learn how to configure and use Chronosphere Observability Platform endpoints for OpenTelemetry protocol ingestion of log data."
      }
    }, {
      name: "pipeline-logs",
      route: "/ingest/logs/pipeline-logs",
      frontMatter: {
        "title": "Route logs through Telemetry Pipeline",
        "description": "Learn how to route log data through Chronosphere Telemetry Pipeline to Chronosphere Observability Platform."
      }
    }, {
      name: "recommended",
      route: "/ingest/logs/recommended",
      frontMatter: {
        "title": "Recommended ingest configuration",
        "description": "Use Telemetry Pipeline to ingest logs to Observability Platform.",
        "robots": "noindex, nofollow"
      }
    }, {
      name: "verify-logs",
      route: "/ingest/logs/verify-logs",
      frontMatter: {
        "title": "Verify Observability Platform is receiving log data",
        "description": "Learn how to verify that Chronosphere Observability Platform is ingesting your log data.",
        "robots": "noindex, nofollow"
      }
    }]
  }, {
    name: "logs-oem",
    route: "/ingest/logs-oem",
    frontMatter: {
      "title": "Ingest log data",
      "description": "Learn about how to ingest log data to Observability Platform.",
      "robots": "noindex, nofollow"
    }
  }, {
    name: "logs",
    route: "/ingest/logs",
    frontMatter: {
      "title": "Ingest log data",
      "description": "Learn about how to ingest log data into Observability Platform."
    }
  }, {
    name: "metrics-traces",
    route: "/ingest/metrics-traces",
    children: [{
      data: ingest_metrics_traces_meta
    }, {
      name: "aws-cloudwatch-metric-stream",
      route: "/ingest/metrics-traces/aws-cloudwatch-metric-stream",
      frontMatter: {
        "title": "Ingest AWS CloudWatch metrics",
        "description": "Send AWS CloudWatch metrics to Observability Platform"
      }
    }, {
      name: "collector",
      route: "/ingest/metrics-traces/collector",
      children: [{
        data: ingest_metrics_traces_collector_meta
      }, {
        name: "addl-metrics",
        route: "/ingest/metrics-traces/collector/addl-metrics",
        children: [{
          data: ingest_metrics_traces_collector_addl_metrics_meta
        }, {
          name: "carbon",
          route: "/ingest/metrics-traces/collector/addl-metrics/carbon",
          frontMatter: {
            "title": "Carbon ingestion"
          }
        }, {
          name: "dogstatsd",
          route: "/ingest/metrics-traces/collector/addl-metrics/dogstatsd",
          frontMatter: {
            "title": "DogStatsD ingestion",
            "description": "Processes and configuration for ingesting DogStatsD metrics into Chronosphere Observability Platform."
          }
        }, {
          name: "prom-openmetrics",
          route: "/ingest/metrics-traces/collector/addl-metrics/prom-openmetrics",
          frontMatter: {
            "title": "Prometheus and OpenMetrics ingestion"
          }
        }, {
          name: "prom-pushgateway",
          route: "/ingest/metrics-traces/collector/addl-metrics/prom-pushgateway",
          frontMatter: {
            "title": "Pushgateway ingestion"
          }
        }, {
          name: "signalfx",
          route: "/ingest/metrics-traces/collector/addl-metrics/signalfx",
          frontMatter: {
            "title": "SignalFx ingestion"
          }
        }, {
          name: "statsd",
          route: "/ingest/metrics-traces/collector/addl-metrics/statsd",
          frontMatter: {
            "title": "StatsD ingestion"
          }
        }, {
          name: "wavefront",
          route: "/ingest/metrics-traces/collector/addl-metrics/wavefront",
          frontMatter: {
            "title": "Wavefront"
          }
        }]
      }, {
        name: "addl-metrics",
        route: "/ingest/metrics-traces/collector/addl-metrics",
        frontMatter: {
          "title": "Ingest additional metrics"
        }
      }, {
        name: "configure",
        route: "/ingest/metrics-traces/collector/configure",
        children: [{
          data: ingest_metrics_traces_collector_configure_meta
        }, {
          name: "optimizations",
          route: "/ingest/metrics-traces/collector/configure/optimizations",
          frontMatter: {
            "title": "Chronosphere Collector optimizations"
          }
        }, {
          name: "prometheus-backend",
          route: "/ingest/metrics-traces/collector/configure/prometheus-backend",
          frontMatter: {
            "title": "Prometheus remote write backend"
          }
        }, {
          name: "scrape-prometheus-native-histograms",
          route: "/ingest/metrics-traces/collector/configure/scrape-prometheus-native-histograms",
          frontMatter: {
            "title": "Scrape Prometheus native histograms"
          }
        }]
      }, {
        name: "configure",
        route: "/ingest/metrics-traces/collector/configure",
        frontMatter: {
          "title": "Configure your Chronosphere Collector"
        }
      }, {
        name: "debugging",
        route: "/ingest/metrics-traces/collector/debugging",
        frontMatter: {
          "title": "Debug endpoints",
          "description": "How to debug Chronosphere Collector issues by using available HTTP endpoints."
        }
      }, {
        name: "discover",
        route: "/ingest/metrics-traces/collector/discover",
        children: [{
          data: ingest_metrics_traces_collector_discover_meta
        }, {
          name: "monitor-kubernetes",
          route: "/ingest/metrics-traces/collector/discover/monitor-kubernetes",
          frontMatter: {
            "title": "Discover and scrape Kubernetes resources"
          }
        }, {
          name: "scrape-configuration",
          route: "/ingest/metrics-traces/collector/discover/scrape-configuration",
          frontMatter: {
            "title": "Scrape configuration using Kubernetes"
          }
        }]
      }, {
        name: "discover",
        route: "/ingest/metrics-traces/collector/discover",
        frontMatter: {
          "title": "Collector service discovery for Prometheus metrics"
        }
      }, {
        name: "install",
        route: "/ingest/metrics-traces/collector/install",
        children: [{
          data: ingest_metrics_traces_collector_install_meta
        }, {
          name: "kubernetes",
          route: "/ingest/metrics-traces/collector/install/kubernetes",
          frontMatter: {
            "title": "Kubernetes Collector installation to retrieve metrics"
          }
        }, {
          name: "standalone",
          route: "/ingest/metrics-traces/collector/install/standalone",
          frontMatter: {
            "title": "Standalone Collector installation to retrieve metrics"
          }
        }, {
          name: "tracing",
          route: "/ingest/metrics-traces/collector/install/tracing",
          frontMatter: {
            "title": "Kubernetes Collector installation to receive traces"
          }
        }, {
          name: "upgrade",
          route: "/ingest/metrics-traces/collector/install/upgrade",
          frontMatter: {
            "title": "Upgrade the Collector"
          }
        }]
      }, {
        name: "install",
        route: "/ingest/metrics-traces/collector/install",
        frontMatter: {
          "title": "Install the Chronosphere Collector"
        }
      }, {
        name: "mappings",
        route: "/ingest/metrics-traces/collector/mappings",
        children: [{
          data: ingest_metrics_traces_collector_mappings_meta
        }, {
          name: "datadog",
          route: "/ingest/metrics-traces/collector/mappings/datadog",
          children: [{
            data: ingest_metrics_traces_collector_mappings_datadog_meta
          }, {
            name: "convert-monitors",
            route: "/ingest/metrics-traces/collector/mappings/datadog/convert-monitors",
            frontMatter: {
              "title": "Convert Datadog monitors"
            }
          }, {
            name: "dashboards",
            route: "/ingest/metrics-traces/collector/mappings/datadog/dashboards",
            frontMatter: {
              "title": "Dashboard widgets"
            }
          }, {
            name: "dogstatsd",
            route: "/ingest/metrics-traces/collector/mappings/datadog/dogstatsd",
            frontMatter: {
              "title": "Querying DogStatsD formatted metrics"
            }
          }, {
            name: "mapping",
            route: "/ingest/metrics-traces/collector/mappings/datadog/mapping",
            frontMatter: {
              "title": "Differences between Datadog and PromQL functions and operators",
              "robots": "noindex, nofollow"
            }
          }, {
            name: "migration-issues",
            route: "/ingest/metrics-traces/collector/mappings/datadog/migration-issues",
            frontMatter: {
              "title": "Datadog migration considerations"
            }
          }]
        }, {
          name: "datadog",
          route: "/ingest/metrics-traces/collector/mappings/datadog",
          frontMatter: {
            "title": "Datadog migration information"
          }
        }, {
          name: "prometheus",
          route: "/ingest/metrics-traces/collector/mappings/prometheus",
          children: [{
            data: ingest_metrics_traces_collector_mappings_prometheus_meta
          }, {
            name: "operator-collector",
            route: "/ingest/metrics-traces/collector/mappings/prometheus/operator-collector",
            frontMatter: {
              "title": "Differences between the Prometheus Operator and the Collector"
            }
          }, {
            name: "prometheus-recommendations",
            route: "/ingest/metrics-traces/collector/mappings/prometheus/prometheus-recommendations",
            frontMatter: {
              "title": "Prometheus metric naming recommendations"
            }
          }]
        }, {
          name: "signalfx",
          route: "/ingest/metrics-traces/collector/mappings/signalfx",
          frontMatter: {
            "title": "Migration notes for SignalFx (Splunk) to PromQL"
          }
        }, {
          name: "wavefront",
          route: "/ingest/metrics-traces/collector/mappings/wavefront",
          frontMatter: {
            "title": "Migration notes for Tanzu (Wavefront) to PromQL"
          }
        }]
      }, {
        name: "mappings",
        route: "/ingest/metrics-traces/collector/mappings",
        frontMatter: {
          "title": "Map data sources to Chronosphere"
        }
      }, {
        name: "monitor",
        route: "/ingest/metrics-traces/collector/monitor",
        children: [{
          data: ingest_metrics_traces_collector_monitor_meta
        }, {
          name: "monitor-collector",
          route: "/ingest/metrics-traces/collector/monitor/monitor-collector",
          frontMatter: {
            "title": "Monitor the Collector"
          }
        }, {
          name: "verify-metrics",
          route: "/ingest/metrics-traces/collector/monitor/verify-metrics",
          frontMatter: {
            "title": "Verify the Collector is scraping metrics"
          }
        }, {
          name: "verify-traces",
          route: "/ingest/metrics-traces/collector/monitor/verify-traces",
          frontMatter: {
            "title": "Verify the Collector is receiving traces"
          }
        }]
      }, {
        name: "monitor",
        route: "/ingest/metrics-traces/collector/monitor",
        frontMatter: {
          "title": "Verify and monitor the Collector"
        }
      }]
    }, {
      name: "collector",
      route: "/ingest/metrics-traces/collector",
      frontMatter: {
        "title": "Chronosphere Collector"
      }
    }, {
      name: "gcp",
      route: "/ingest/metrics-traces/gcp",
      frontMatter: {
        "title": "Ingest Google Cloud metrics",
        "description": "Learn how to ingest Google Cloud metrics into Chronosphere Observability Platform."
      }
    }, {
      name: "otel",
      route: "/ingest/metrics-traces/otel",
      children: [{
        data: ingest_metrics_traces_otel_meta
      }, {
        name: "ingest-dogstatsd",
        route: "/ingest/metrics-traces/otel/ingest-dogstatsd",
        frontMatter: {
          "title": "Ingest DogStatsD metrics with the OpenTelemetry Collector",
          "description": "How to use the OpenTelemetry Collector to ingest DogStatsD metrics."
        }
      }, {
        name: "otel-ingest-config",
        route: "/ingest/metrics-traces/otel/otel-ingest-config",
        frontMatter: {
          "title": "Configure ingestion and processing of OpenTelemetry Metrics",
          "description": "Learn how to configure Chronosphere Observability Platform's ingestion and processing of OpenTelemetry Metrics data"
        }
      }, {
        name: "otel-ingest",
        route: "/ingest/metrics-traces/otel/otel-ingest",
        frontMatter: {
          "title": "Configure your OpenTelemetry Collector",
          "description": "Learn about configuring the OpenTelemetry Collector for metric and tracing data."
        }
      }, {
        name: "otlp-endpoints",
        route: "/ingest/metrics-traces/otel/otlp-endpoints",
        frontMatter: {
          "title": "Use OpenTelemetry protocol endpoints",
          "description": "Learn how to configure and use Chronosphere Observability Platform's endpoints for OpenTelemetry protocol ingestion of metrics and traces."
        }
      }]
    }, {
      name: "otel",
      route: "/ingest/metrics-traces/otel",
      frontMatter: {
        "title": "Ingest metrics and traces using the OpenTelemetry Collector",
        "description": "Use the OpenTelemetry Collector to collect metrics and traces and transfer them to Chronosphere for analysis."
      }
    }, {
      name: "troubleshooting",
      route: "/ingest/metrics-traces/troubleshooting",
      frontMatter: {
        "title": "Troubleshooting ingestion"
      }
    }]
  }, {
    name: "metrics-traces",
    route: "/ingest/metrics-traces",
    frontMatter: {
      "title": "Ingest metrics and traces",
      "description": "Learn about the collection methods used to ingest metrics and traces into Chronosphere Observability Platform."
    }
  }, {
    name: "third-party",
    route: "/ingest/third-party",
    children: [{
      data: ingest_third_party_meta
    }, {
      name: "aws-eventbridge",
      route: "/ingest/third-party/aws-eventbridge",
      frontMatter: {
        "title": "Send Amazon EventBridge events to Observability Platform"
      }
    }, {
      name: "buildkite",
      route: "/ingest/third-party/buildkite",
      frontMatter: {
        "title": "Send Buildkite events to Observability Platform"
      }
    }, {
      name: "circleci",
      route: "/ingest/third-party/circleci",
      frontMatter: {
        "title": "Send CircleCI events to Observability Platform"
      }
    }, {
      name: "github",
      route: "/ingest/third-party/github",
      frontMatter: {
        "title": "Send GitHub events to Observability Platform",
        "description": "Create a webhook to send your GitHub events to Chronosphere Observability Platform as change events."
      }
    }, {
      name: "gitlab",
      route: "/ingest/third-party/gitlab",
      frontMatter: {
        "title": "Send GitLab events to Observability Platform"
      }
    }, {
      name: "google-cloud",
      route: "/ingest/third-party/google-cloud",
      frontMatter: {
        "title": "Send Google Cloud Personalized Service Health events to Observability Platform"
      }
    }, {
      name: "harness",
      route: "/ingest/third-party/harness",
      frontMatter: {
        "title": "Send Harness events to Observability Platform"
      }
    }, {
      name: "launchdarkly",
      route: "/ingest/third-party/launchdarkly",
      frontMatter: {
        "title": "Send LaunchDarkly events to Observability Platform"
      }
    }]
  }, {
    name: "third-party",
    route: "/ingest/third-party",
    frontMatter: {
      "title": "Send third-party events to Chronosphere"
    }
  }]
}, {
  name: "ingest",
  route: "/ingest",
  frontMatter: {
    "title": "Ingest telemetry data",
    "description": "Use control mechanisms to manage the telemetry data you ingest into Chronosphere Observability Platform."
  }
}, {
  name: "investigate",
  route: "/investigate",
  children: [{
    data: investigate_meta
  }, {
    name: "change-events",
    route: "/investigate/change-events",
    children: [{
      data: investigate_change_events_meta
    }, {
      name: "enable-events",
      route: "/investigate/change-events/enable-events",
      children: [{
        data: investigate_change_events_enable_events_meta
      }, {
        name: "dashboards",
        route: "/investigate/change-events/enable-events/dashboards",
        frontMatter: {
          "title": "Enable change events on dashboards"
        }
      }, {
        name: "services",
        route: "/investigate/change-events/enable-events/services",
        frontMatter: {
          "title": "Enable change events on service pages",
          "description": "Learn how to enable and overlay change events on service pages in Observability Platform."
        }
      }, {
        name: "traces",
        route: "/investigate/change-events/enable-events/traces",
        frontMatter: {
          "title": "Enable change events on traces",
          "description": "Learn how to enable and overlay change events on tracing data in Trace Explorer."
        }
      }]
    }, {
      name: "enable-events",
      route: "/investigate/change-events/enable-events",
      frontMatter: {
        "title": "Enable change events",
        "description": "Enable change events in Observability Platform to understand what changed in your environment."
      }
    }, {
      name: "filter-events",
      route: "/investigate/change-events/filter-events",
      frontMatter: {
        "title": "Filter change events to identify root causes",
        "description": "Filter change events in Chronosphere Observability Platform to identify the root cause of issues."
      }
    }, {
      name: "use-events",
      route: "/investigate/change-events/use-events",
      frontMatter: {
        "title": "Use change events",
        "description": "Use change events in Chronosphere Observability Platform to understand what changed in your environment."
      }
    }]
  }, {
    name: "change-events",
    route: "/investigate/change-events",
    frontMatter: {
      "title": "Understand what changed in your environment",
      "description": "Learn about how to use change events to understand what changed in your environment."
    }
  }, {
    name: "differential-diagnosis",
    route: "/investigate/differential-diagnosis",
    children: [{
      data: investigate_differential_diagnosis_meta
    }, {
      name: "metrics",
      route: "/investigate/differential-diagnosis/metrics",
      frontMatter: {
        "title": "Investigate suspicious metrics",
        "description": "Learn how to use differential diagnosis to identify metrics most closely correlated with suspicious behavior.",
        "robots": "noindex, nofollow"
      }
    }, {
      name: "traces",
      route: "/investigate/differential-diagnosis/traces",
      frontMatter: {
        "title": "Identify issues behind suspicious tracing trends",
        "description": "Learn how to use differential diagnosis in Trace Explorer to identify tag:value pairs most closely correlated with suspicious behavior."
      }
    }]
  }, {
    name: "differential-diagnosis",
    route: "/investigate/differential-diagnosis",
    frontMatter: {
      "title": "Identify issues behind suspicious trends",
      "description": "Learn how to use differential diagnosis to identify issues in your telemetry data most closely correlated with suspicious behavior."
    }
  }, {
    name: "logs",
    route: "/investigate/logs",
    children: [{
      data: investigate_logs_meta
    }, {
      name: "alerting",
      route: "/investigate/logs/alerting",
      children: [{
        data: investigate_logs_alerting_meta
      }, {
        name: "actions",
        route: "/investigate/logs/alerting/actions",
        frontMatter: {
          "title": "Create actions for alerts and scheduled searches",
          "description": "Learn about how to create actions for alerts in Chronosphere Logs.",
          "robots": "noindex, nofollow"
        }
      }, {
        name: "alerts",
        route: "/investigate/logs/alerting/alerts",
        frontMatter: {
          "title": "Create alerts for log queries",
          "description": "Learn about how to create alerts for log queries in Chronosphere Logs.",
          "robots": "noindex, nofollow"
        }
      }, {
        name: "searches",
        route: "/investigate/logs/alerting/searches",
        frontMatter: {
          "title": "Create scheduled searches",
          "description": "Learn about how to create scheduled searches in Chronosphere Logs.",
          "robots": "noindex, nofollow"
        }
      }]
    }, {
      name: "alerting",
      route: "/investigate/logs/alerting",
      frontMatter: {
        "title": "Alert on logs",
        "description": "Learn about how to create alerts for Chronosphere Observability Platform logging.",
        "robots": "noindex, nofollow"
      }
    }, {
      name: "dashboards",
      route: "/investigate/logs/dashboards",
      frontMatter: {
        "title": "Create dashboards for logging",
        "description": "Learn about how to create dashboards for Chronosphere Observability Platform logging.",
        "robots": "noindex, nofollow"
      }
    }, {
      name: "external-tasks",
      route: "/investigate/logs/external-tasks",
      frontMatter: {
        "title": "External LogScale configuration",
        "description": "Learn about how to access administrative features for LogScale.",
        "robots": "noindex, nofollow"
      }
    }, {
      name: "key-concepts",
      route: "/investigate/logs/key-concepts",
      frontMatter: {
        "title": "Key LogScale concepts",
        "description": "Learn about key LogScale concepts that can help you use Logs.",
        "robots": "noindex, nofollow"
      }
    }, {
      name: "logscale-configuration",
      route: "/investigate/logs/logscale-configuration",
      frontMatter: {
        "title": "Configure LogScale",
        "description": "Learn about how to configure LogScale to start using Chronosphere Observability Platform logging.",
        "robots": "noindex, nofollow"
      }
    }]
  }, {
    name: "logs-oem",
    route: "/investigate/logs-oem",
    frontMatter: {
      "title": "Parse, ingest, and query your log data",
      "description": "Learn about how to get started with Chronosphere Observability Platform logging.",
      "robots": "noindex, nofollow"
    }
  }, {
    name: "logs",
    route: "/investigate/logs",
    frontMatter: {
      "title": "Ingest, parse, and query your log data",
      "description": "Learn about how to get started with Chronosphere Observability Platform logging."
    }
  }, {
    name: "metrics",
    route: "/investigate/metrics",
    children: [{
      data: investigate_metrics_meta
    }, {
      name: "analyzer",
      route: "/investigate/metrics/analyzer",
      frontMatter: {
        "title": "Analyze live traffic metrics"
      }
    }, {
      name: "dictionary",
      route: "/investigate/metrics/dictionary",
      frontMatter: {
        "title": "Metrics dictionary",
        "description": "Use the metrics dictionary to learn about metrics created by and specific to Chronosphere Observability Platform."
      }
    }, {
      name: "troubleshooting",
      route: "/investigate/metrics/troubleshooting",
      frontMatter: {
        "title": "Troubleshooting missing metrics"
      }
    }, {
      name: "usage",
      route: "/investigate/metrics/usage",
      frontMatter: {
        "title": "Analyze usage metrics"
      }
    }]
  }, {
    name: "metrics",
    route: "/investigate/metrics",
    frontMatter: {
      "title": "Analyze and investigate your metric data",
      "description": "Learn about how Observability Platform can help you understand what's driving data storage volume and use."
    }
  }, {
    name: "querying",
    route: "/investigate/querying",
    children: [{
      data: investigate_querying_meta
    }, {
      name: "create-links",
      route: "/investigate/querying/create-links",
      frontMatter: {
        "title": "Create links to related information",
        "description": "Learn how to create links from one telemetry type to other information in Observability Platform."
      }
    }, {
      name: "dashboard-query",
      route: "/investigate/querying/dashboard-query",
      frontMatter: {
        "title": "Create dashboards from queries",
        "description": "Learn how to create dashboards that include telemetry data queries in Chronosphere Observability Platform."
      }
    }, {
      name: "glob-syntax",
      route: "/investigate/querying/glob-syntax",
      frontMatter: {
        "title": "Glob syntax",
        "description": "Learn how to use glob syntax patterns in queries."
      }
    }, {
      name: "logs",
      route: "/investigate/querying/logs",
      frontMatter: {
        "title": "Search and query log data",
        "description": "Learn about how to query log data in Chronosphere Observability Platform.",
        "robots": "noindex, nofollow"
      }
    }, {
      name: "metrics",
      route: "/investigate/querying/metrics",
      children: [{
        data: investigate_querying_metrics_meta
      }, {
        name: "builder",
        route: "/investigate/querying/metrics/builder",
        frontMatter: {
          "title": "Create queries with the Query Builder",
          "description": "Learn how to use the Query Builder to find and analyze data."
        }
      }, {
        name: "delta-queries",
        route: "/investigate/querying/metrics/delta-queries",
        frontMatter: {
          "title": "Querying delta temporality metrics"
        }
      }, {
        name: "derived-labels",
        route: "/investigate/querying/metrics/derived-labels",
        frontMatter: {
          "title": "Derived labels",
          "description": "Learn about derived labels and how you can to augment a time series after they're persisted."
        }
      }, {
        name: "derived-metrics",
        route: "/investigate/querying/metrics/derived-metrics",
        frontMatter: {
          "title": "Derived metrics",
          "description": "Learn about derived metrics and how you can create aliases for queries."
        }
      }, {
        name: "explorer-classic",
        route: "/investigate/querying/metrics/explorer-classic",
        frontMatter: {
          "title": "Classic Metrics Explorer"
        }
      }, {
        name: "explorer",
        route: "/investigate/querying/metrics/explorer",
        frontMatter: {
          "title": "Metrics Explorer"
        }
      }, {
        name: "fixing",
        route: "/investigate/querying/metrics/fixing",
        frontMatter: {
          "title": "Fixing slow queries and dashboards",
          "description": "Learn about ways to improve slow queries and dashboards to avoid query truncation."
        }
      }, {
        name: "promql",
        route: "/investigate/querying/metrics/promql",
        frontMatter: {
          "title": "Using PromQL in Observability Platform"
        }
      }, {
        name: "query-analyzer",
        route: "/investigate/querying/metrics/query-analyzer",
        frontMatter: {
          "title": "Query Analyzer"
        }
      }]
    }, {
      name: "metrics",
      route: "/investigate/querying/metrics",
      frontMatter: {
        "title": "Search and query metrics",
        "description": "Learn how to search and query metrics in Metrics Explorer."
      }
    }, {
      name: "query-logs",
      route: "/investigate/querying/query-logs",
      children: [{
        data: investigate_querying_query_logs_meta
      }, {
        name: "query-syntax",
        route: "/investigate/querying/query-logs/query-syntax",
        frontMatter: {
          "title": "Logging query syntax",
          "description": "Learn about the syntax for querying logs in Chronosphere Observability Platform.",
          "robots": "noindex, nofollow"
        }
      }]
    }, {
      name: "query-logs",
      route: "/investigate/querying/query-logs",
      frontMatter: {
        "title": "Query log data",
        "description": "Learn how to query logs in Chronosphere Observability Platform."
      }
    }, {
      name: "regular-expressions",
      route: "/investigate/querying/regular-expressions",
      frontMatter: {
        "title": "Regular expressions",
        "description": "Learn about which regular expression engine to use when querying data in Chronosphere Observability Platform."
      }
    }, {
      name: "traces",
      route: "/investigate/querying/traces",
      frontMatter: {
        "title": "Search and filter trace data",
        "description": "Learn how to search and filter trace data."
      }
    }]
  }, {
    name: "querying",
    route: "/investigate/querying",
    frontMatter: {
      "title": "Search and query telemetry data",
      "description": "Learn about how to query your telemetry data from Observability Platform."
    }
  }, {
    name: "traces",
    route: "/investigate/traces",
    children: [{
      data: investigate_traces_meta
    }, {
      name: "analyzer",
      route: "/investigate/traces/analyzer",
      frontMatter: {
        "title": "Trace Analyzer",
        "description": "Use Trace Analyzer in Chronosphere Observability Platform to view incoming traces."
      }
    }, {
      name: "examples",
      route: "/investigate/traces/examples",
      frontMatter: {
        "title": "Examples for tracing",
        "description": "Learn how to use distributed tracing tools in Chronosphere Observability Platform."
      }
    }, {
      name: "explorer",
      route: "/investigate/traces/explorer",
      children: [{
        data: investigate_traces_explorer_meta
      }, {
        name: "features",
        route: "/investigate/traces/explorer/features",
        frontMatter: {
          "title": "Trace Explorer features overview",
          "description": "Learn about the features in Trace Explorer you can use to explore trace data."
        }
      }, {
        name: "services",
        route: "/investigate/traces/explorer/services",
        frontMatter: {
          "title": "View connected services",
          "description": "Learn how to view connected tracing services to help troubleshoot issues."
        }
      }]
    }, {
      name: "explorer",
      route: "/investigate/traces/explorer",
      frontMatter: {
        "title": "Trace Explorer",
        "description": "Use Trace Explorer in Chronosphere Observability Platform to search for traces and spans to understand the root cause of issues."
      }
    }, {
      name: "trace-metrics",
      route: "/investigate/traces/trace-metrics",
      frontMatter: {
        "title": "Trace metrics",
        "description": "Learn about how to create and use trace metrics to help address issues."
      }
    }]
  }, {
    name: "traces",
    route: "/investigate/traces",
    frontMatter: {
      "title": "Identify issues across your distributed system",
      "description": "Learn about distributed tracing in Chronosphere Observability Platform."
    }
  }]
}, {
  name: "investigate",
  route: "/investigate",
  frontMatter: {
    "title": "Investigate your telemetry data",
    "description": "Chronosphere Observability Platform offers tools for metric and trace analysis, providing insights that help with troubleshooting and resolution."
  }
}, {
  name: "navigate",
  route: "/navigate",
  children: [{
    data: navigate_meta
  }, {
    name: "pinned-scopes",
    route: "/navigate/pinned-scopes",
    frontMatter: {
      "sidebarTitle": "Pinned Scopes"
    }
  }, {
    name: "time-ranges",
    route: "/navigate/time-ranges",
    frontMatter: {
      "title": "Select time ranges"
    }
  }]
}, {
  name: "navigate",
  route: "/navigate",
  frontMatter: {
    "title": "Navigate the Observability Platform interface",
    "description": "Learn how to navigate the Chronosphere Observability Platform to find the functions you need to gain insight into your platforms."
  }
}, {
  name: "observe",
  route: "/observe",
  children: [{
    data: observe_meta
  }, {
    name: "dashboards",
    route: "/observe/dashboards",
    children: [{
      data: observe_dashboards_meta
    }, {
      name: "chronosphere-managed-dashboards",
      route: "/observe/dashboards/chronosphere-managed-dashboards",
      frontMatter: {
        "title": "Chronosphere-managed dashboards",
        "description": "Learn about dashboards included with and managed by Chronosphere."
      }
    }, {
      name: "classic-dashboards",
      route: "/observe/dashboards/classic-dashboards",
      children: [{
        data: observe_dashboards_classic_dashboards_meta
      }, {
        name: "classic-panels",
        route: "/observe/dashboards/classic-dashboards/classic-panels",
        frontMatter: {
          "title": "Visualize dashboard contents with panels"
        }
      }, {
        name: "import-grafana-dashboards",
        route: "/observe/dashboards/classic-dashboards/import-grafana-dashboards",
        frontMatter: {
          "title": "Import Grafana dashboards"
        }
      }]
    }, {
      name: "classic-dashboards",
      route: "/observe/dashboards/classic-dashboards",
      frontMatter: {
        "title": "Classic dashboards"
      }
    }, {
      name: "customization",
      route: "/observe/dashboards/customization",
      children: [{
        data: observe_dashboards_customization_meta
      }, {
        name: "dashboard-variables",
        route: "/observe/dashboards/customization/dashboard-variables",
        frontMatter: {
          "title": "Customize dashboard queries with dashboard variables",
          "description": "Create dashboard variables to help dashboard users quickly customize queries with predefined values."
        }
      }, {
        name: "layouts",
        route: "/observe/dashboards/customization/layouts",
        frontMatter: {
          "title": "Organize dashboard content with layouts",
          "description": "Organize data visualization panels in Chronosphere dashboards with layouts composed of panel groups."
        }
      }, {
        name: "time-ranges",
        route: "/observe/dashboards/customization/time-ranges",
        frontMatter: {
          "title": "Manage dashboard time ranges",
          "description": "Analyze and correlate the contents of data visualization panels in Chronosphere dashboards by setting time ranges."
        }
      }]
    }, {
      name: "customization",
      route: "/observe/dashboards/customization",
      frontMatter: {
        "title": "Customize dashboards",
        "description": "Customize dashboard features in Chronosphere."
      }
    }, {
      name: "panels",
      route: "/observe/dashboards/panels",
      children: [{
        data: observe_dashboards_panels_meta
      }, {
        name: "bar-chart",
        route: "/observe/dashboards/panels/bar-chart",
        frontMatter: {
          "title": "Visualize relative data with bar charts",
          "description": "Create and customize bar chart data visualization panels for Chronosphere Observability Platform dashboards."
        }
      }, {
        name: "gauge-chart",
        route: "/observe/dashboards/panels/gauge-chart",
        frontMatter: {
          "title": "Visualize values in ranges with gauge charts",
          "description": "Create and customize gauge chart data visualization panels for Chronosphere dashboards."
        }
      }, {
        name: "heat-map",
        route: "/observe/dashboards/panels/heat-map",
        frontMatter: {
          "title": "Visualize data density and histograms with heat maps",
          "description": "Create and customize heat map data visualization panels for Chronosphere Observability Platform dashboards."
        }
      }, {
        name: "json-representation",
        route: "/observe/dashboards/panels/json-representation",
        frontMatter: {
          "title": "Visualize dashboard content with panels",
          "description": "Create and customize data visualization panels for Chronosphere dashboards."
        }
      }, {
        name: "markdown",
        route: "/observe/dashboards/panels/markdown",
        frontMatter: {
          "title": "Document dashboards with Markdown panels",
          "description": "Document dashboards with Markdown panels for Chronosphere dashboards."
        }
      }, {
        name: "pie-chart",
        route: "/observe/dashboards/panels/pie-chart",
        frontMatter: {
          "title": "Visualize proportional data with pie charts",
          "description": "Create and customize pie chart data visualization panels for Chronosphere Observability Platform dashboards."
        }
      }, {
        name: "stat-chart",
        route: "/observe/dashboards/panels/stat-chart",
        frontMatter: {
          "title": "Visualize large statistic numbers with stat charts",
          "description": "Create and customize stat chart data visualization panels for Chronosphere dashboards."
        }
      }, {
        name: "table",
        route: "/observe/dashboards/panels/table",
        frontMatter: {
          "title": "Display data in a table",
          "description": "Create and customize tables of data from query results for Chronosphere Observability Platform dashboards."
        }
      }, {
        name: "time-series-chart",
        route: "/observe/dashboards/panels/time-series-chart",
        frontMatter: {
          "title": "Visualize time series data",
          "description": "Create and customize time series data visualization panels for Chronosphere Observability Platform dashboards."
        }
      }]
    }, {
      name: "panels",
      route: "/observe/dashboards/panels",
      frontMatter: {
        "title": "Visualize dashboard content with panels",
        "description": "Create and customize data visualization panels for Chronosphere Observability Platform dashboards."
      }
    }, {
      name: "query-traces",
      route: "/observe/dashboards/query-traces",
      frontMatter: {
        "title": "Query traces in dashboards",
        "description": "Learn how to query traces from within a Chronosphere Observability Platform dashboard."
      }
    }]
  }, {
    name: "dashboards",
    route: "/observe/dashboards",
    frontMatter: {
      "title": "Visualize data using dashboards",
      "description": "Use dashboards to create and organize data visualizations in Chronosphere Observability Platform."
    }
  }, {
    name: "services",
    route: "/observe/services",
    children: [{
      data: observe_services_meta
    }, {
      name: "service-exploration",
      route: "/observe/services/service-exploration",
      frontMatter: {
        "title": "Service exploration",
        "description": "Learn about how to use services and traces to find issues."
      }
    }, {
      name: "service-pages",
      route: "/observe/services/service-pages",
      frontMatter: {
        "title": "Service pages",
        "description": "Learn about the different aspects of service pages and how to view data related to your services."
      }
    }]
  }, {
    name: "services",
    route: "/observe/services",
    frontMatter: {
      "title": "Services",
      "description": "Learn about services in Chronosphere Observability Platform."
    }
  }, {
    name: "slo",
    route: "/observe/slo",
    children: [{
      data: observe_slo_meta
    }, {
      name: "create",
      route: "/observe/slo/create",
      frontMatter: {
        "title": "Create service level objectives",
        "description": "Learn about creating service level objectives (SLOs) in Chronosphere Observability Platform.",
        "robots": "noindex, nofollow"
      }
    }]
  }, {
    name: "slo",
    route: "/observe/slo",
    frontMatter: {
      "title": "Service level objectives",
      "description": "Learn about service level objectives (SLOs) in Chronosphere Observability Platform.",
      "robots": "noindex, nofollow"
    }
  }]
}, {
  name: "observe",
  route: "/observe",
  frontMatter: {
    "title": "Observe your telemetry data",
    "description": "Chronosphere Observability Platform provides scalable, high-performance observability with metrics, tracing, and logging for complex system monitoring."
  }
}, {
  name: "overview",
  route: "/overview",
  children: [{
    data: overview_meta
  }, {
    name: "architecture",
    route: "/overview/architecture",
    frontMatter: {
      "title": "About the Observability Platform architecture",
      "description": "Learn about the Chronosphere Observability Platform architecture."
    }
  }, {
    name: "authenticating",
    route: "/overview/authenticating",
    frontMatter: {
      "title": "Authenticate with Observability Platform as a user"
    }
  }, {
    name: "common-tasks",
    route: "/overview/common-tasks",
    frontMatter: {
      "title": "Common Observability Platform tasks",
      "description": "Learn about common workflows in Chronosphere Observability Platform."
    }
  }, {
    name: "concepts",
    route: "/overview/concepts",
    frontMatter: {
      "title": "Observability Platform concepts",
      "description": "Learn more about components, concepts, and terms used by Chronosphere Observability Platform."
    }
  }]
}, {
  name: "overview",
  route: "/overview",
  frontMatter: {
    "title": "Chronosphere Observability Platform",
    "description": "Learn about the Chronosphere Observability Platform, its capabilities, and architecture."
  }
}, {
  name: "pipeline-administer",
  route: "/pipeline-administer",
  children: [{
    data: pipeline_administer_meta
  }, {
    name: "monitoring",
    route: "/pipeline-administer/monitoring",
    frontMatter: {
      "title": "Monitoring",
      "description": "Monitor your pipelines, fleets, and other components of Chronosphere Telemetry Pipeline."
    }
  }, {
    name: "sso",
    route: "/pipeline-administer/sso",
    frontMatter: {
      "title": "Set up single sign-on",
      "description": "Set up SSO for Telemetry Pipeline through your organization's identity provider."
    }
  }]
}, {
  name: "pipeline-administer",
  route: "/pipeline-administer",
  frontMatter: {
    "title": "Administer Chronosphere Telemetry Pipeline",
    "description": "Learn how to manage tools and features of Chronosphere Telemetry Pipeline."
  }
}, {
  name: "pipeline-cli",
  route: "/pipeline-cli",
  children: [{
    data: pipeline_cli_meta
  }, {
    name: "authenticate",
    route: "/pipeline-cli/authenticate",
    frontMatter: {
      "title": "Authenticate in Pipeline CLI",
      "description": "Learn how to authenticate with Chronosphere through Pipeline CLI."
    }
  }, {
    name: "install",
    route: "/pipeline-cli/install",
    frontMatter: {
      "title": "Install Pipeline CLI",
      "description": "Learn how to install Pipeline CLI."
    }
  }]
}, {
  name: "pipeline-cli",
  route: "/pipeline-cli",
  frontMatter: {
    "title": "Pipeline CLI",
    "description": "Learn about the Chronosphere Telemetry Pipeline command-line tool."
  }
}, {
  name: "pipeline-configure",
  route: "/pipeline-configure",
  children: [{
    data: pipeline_configure_meta
  }, {
    name: "config-files",
    route: "/pipeline-configure/config-files",
    frontMatter: {
      "title": "Pipeline configuration files",
      "description": "Use configuration files to customize your pipelines."
    }
  }, {
    name: "deployment-strategies",
    route: "/pipeline-configure/deployment-strategies",
    frontMatter: {
      "title": "Pipeline deployment strategies",
      "description": "Set deployment strategies for pipeliens in Chronosphere Telemetry Pipeline."
    }
  }, {
    name: "files",
    route: "/pipeline-configure/files",
    frontMatter: {
      "title": "Files",
      "description": "Add files to pipelines in Chronosphere Telemetry Pipeline."
    }
  }, {
    name: "helm",
    route: "/pipeline-configure/helm",
    frontMatter: {
      "title": "Configure Telemetry Pipeline using Helm",
      "description": "Use Helm to configure Chronosphere Telemetry Pipeline."
    }
  }, {
    name: "history-rollback",
    route: "/pipeline-configure/history-rollback",
    frontMatter: {
      "title": "History and rollback",
      "description": "View your pipeline history and revert to previous versions."
    }
  }, {
    name: "http-proxy",
    route: "/pipeline-configure/http-proxy",
    frontMatter: {
      "title": "Use an HTTP proxy",
      "description": "Use an HTTP proxy with Chronosphere Telemetry Pipeline."
    }
  }, {
    name: "kubernetes-deployment",
    route: "/pipeline-configure/kubernetes-deployment",
    frontMatter: {
      "title": "Kubernetes deployment settings",
      "description": "Configure your Kubernetes deployment settings in Chronosphere Telemetry Pipeline."
    }
  }, {
    name: "metadata",
    route: "/pipeline-configure/metadata",
    frontMatter: {
      "title": "Pipeline metadata",
      "description": "Access pipeline metadata in Chronosphere Telemetry Pipeline."
    }
  }, {
    name: "ports",
    route: "/pipeline-configure/ports",
    frontMatter: {
      "title": "Ports",
      "description": "Learn about ports in Chronosphere Telemetry Pipeline."
    }
  }, {
    name: "resource-labels",
    route: "/pipeline-configure/resource-labels",
    frontMatter: {
      "title": "Resource labels",
      "description": "Learn about the labels assigned to Telemetry Pipeline resources."
    }
  }, {
    name: "resource-profiles",
    route: "/pipeline-configure/resource-profiles",
    frontMatter: {
      "title": "Resource profiles",
      "description": "Optimize pipeline performance with resource profiles."
    }
  }, {
    name: "scaling",
    route: "/pipeline-configure/scaling",
    frontMatter: {
      "title": "Scaling and replicas (Telemetry Pipeline)",
      "description": "Learn how Chronosphere Telemetry Pipeline uses scaling and replicas."
    }
  }, {
    name: "secrets",
    route: "/pipeline-configure/secrets",
    frontMatter: {
      "title": "Secrets (Telemetry Pipeline)",
      "description": "Learn how to use secrets in your Chronosphere Telemetry Pipeline pipelines."
    }
  }, {
    name: "service-types",
    route: "/pipeline-configure/service-types",
    frontMatter: {
      "title": "Service types",
      "description": "Learn about Kubernetes Service types in Chronosphere Telemetry Pipeline."
    }
  }, {
    name: "sidecars",
    route: "/pipeline-configure/sidecars",
    frontMatter: {
      "title": "Pipeline sidecars",
      "description": "Learn about pipeline sidecars in Chronosphere Telemetry Pipeline."
    }
  }]
}, {
  name: "pipeline-configure",
  route: "/pipeline-configure",
  frontMatter: {
    "title": "Configure Chronosphere Telemetry Pipeline",
    "description": "Learn how to configure Chronosphere Telemetry Pipeline."
  }
}, {
  name: "pipeline-data",
  route: "/pipeline-data",
  children: [{
    data: pipeline_data_meta
  }, {
    name: "automated-logging",
    route: "/pipeline-data/automated-logging",
    frontMatter: {
      "title": "Automated Kubernetes logging",
      "description": "Use Chronosphere Telemetry Pipeline to route logs from a Kubernetes cluster."
    }
  }, {
    name: "create-modify",
    route: "/pipeline-data/create-modify",
    frontMatter: {
      "title": "Create and modify pipelines",
      "description": "Learn how to create, modify, and delete custom pipelines."
    }
  }, {
    name: "parsers",
    route: "/pipeline-data/parsers",
    frontMatter: {
      "title": "Parsers (Telemetry Pipeline)",
      "description": "Use Chronosphere Telemetry Pipeline to parse data."
    }
  }, {
    name: "plugins",
    route: "/pipeline-data/plugins",
    children: [{
      data: pipeline_data_plugins_meta
    }, {
      name: "destination-plugins",
      route: "/pipeline-data/plugins/destination-plugins",
      children: [{
        data: pipeline_data_plugins_destination_plugins_meta
      }, {
        name: "amazon-cloudwatch",
        route: "/pipeline-data/plugins/destination-plugins/amazon-cloudwatch",
        frontMatter: {
          "title": "Amazon CloudWatch Logs and Metrics destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Amazon CloudWatch."
        }
      }, {
        name: "amazon-kinesis-firehose",
        route: "/pipeline-data/plugins/destination-plugins/amazon-kinesis-firehose",
        frontMatter: {
          "title": "Amazon Kinesis Firehose destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Amazon Kinesis Firehose."
        }
      }, {
        name: "amazon-kinesis-streams",
        route: "/pipeline-data/plugins/destination-plugins/amazon-kinesis-streams",
        frontMatter: {
          "title": "Amazon Kinesis Streams destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Amazon Kinesis Streams."
        }
      }, {
        name: "amazon-s3",
        route: "/pipeline-data/plugins/destination-plugins/amazon-s3",
        frontMatter: {
          "title": "Amazon S3 destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Amazon S3."
        }
      }, {
        name: "axiom",
        route: "/pipeline-data/plugins/destination-plugins/axiom",
        frontMatter: {
          "title": "Axiom destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Axiom."
        }
      }, {
        name: "azure-blob-storage",
        route: "/pipeline-data/plugins/destination-plugins/azure-blob-storage",
        frontMatter: {
          "title": "Azure Blob Storage destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Azure Blob Storage."
        }
      }, {
        name: "azure-data-explorer",
        route: "/pipeline-data/plugins/destination-plugins/azure-data-explorer",
        frontMatter: {
          "title": "Azure Data Explorer destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Azure Data Explorer."
        }
      }, {
        name: "azure-eventhub",
        route: "/pipeline-data/plugins/destination-plugins/azure-eventhub",
        frontMatter: {
          "title": "Azure Event Hubs destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Azure Event Hubs."
        }
      }, {
        name: "azure-ingestion-api",
        route: "/pipeline-data/plugins/destination-plugins/azure-ingestion-api",
        frontMatter: {
          "title": "Azure Logs Ingestion API destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Azure Logs Ingestion API."
        }
      }, {
        name: "azure-monitor",
        route: "/pipeline-data/plugins/destination-plugins/azure-monitor",
        frontMatter: {
          "title": "Azure Monitor destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Azure Monitor."
        }
      }, {
        name: "azure-sentinel",
        route: "/pipeline-data/plugins/destination-plugins/azure-sentinel",
        frontMatter: {
          "title": "Azure Sentinel destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Azure Sentinel."
        }
      }, {
        name: "chronosphere",
        route: "/pipeline-data/plugins/destination-plugins/chronosphere",
        frontMatter: {
          "title": "Chronosphere Logs destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Chronosphere."
        }
      }, {
        name: "clickhouse",
        route: "/pipeline-data/plugins/destination-plugins/clickhouse",
        frontMatter: {
          "title": "Clickhouse destination plugin (Telemetry Pipeline)",
          "description": "Use the Clickhouse destination plugin."
        }
      }, {
        name: "confluent-cloud",
        route: "/pipeline-data/plugins/destination-plugins/confluent-cloud",
        frontMatter: {
          "title": "Confluent Cloud destination plugin (Telemetry Pipeline)",
          "description": "Use the Confluent Cloud destination plugin."
        }
      }, {
        name: "coralogix",
        route: "/pipeline-data/plugins/destination-plugins/coralogix",
        frontMatter: {
          "title": "Coralogix destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Coralogix."
        }
      }, {
        name: "crowdstrike",
        route: "/pipeline-data/plugins/destination-plugins/crowdstrike",
        frontMatter: {
          "title": "CrowdStrike destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to CrowdStrike."
        }
      }, {
        name: "datadog",
        route: "/pipeline-data/plugins/destination-plugins/datadog",
        frontMatter: {
          "title": "Datadog Logs destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Datadog."
        }
      }, {
        name: "devo",
        route: "/pipeline-data/plugins/destination-plugins/devo",
        frontMatter: {
          "title": "Devo Cloud destination plugin (Telemetry Pipeline)",
          "description": "Use the Devo Cloud destination plugin."
        }
      }, {
        name: "dynatrace",
        route: "/pipeline-data/plugins/destination-plugins/dynatrace",
        frontMatter: {
          "title": "Dynatrace Logs destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Dynatrace."
        }
      }, {
        name: "elasticsearch",
        route: "/pipeline-data/plugins/destination-plugins/elasticsearch",
        frontMatter: {
          "title": "Elasticsearch destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Elasticsearch."
        }
      }, {
        name: "exabeam",
        route: "/pipeline-data/plugins/destination-plugins/exabeam",
        frontMatter: {
          "title": "Exabeam destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Exabeam."
        }
      }, {
        name: "forward",
        route: "/pipeline-data/plugins/destination-plugins/forward",
        frontMatter: {
          "title": "Forward destination plugin (Telemetry Pipeline)",
          "description": "Use the forward destination plugin."
        }
      }, {
        name: "google-bigquery",
        route: "/pipeline-data/plugins/destination-plugins/google-bigquery",
        frontMatter: {
          "title": "Google Cloud BigQuery destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Google Cloud BigQuery."
        }
      }, {
        name: "google-chronicle",
        route: "/pipeline-data/plugins/destination-plugins/google-chronicle",
        frontMatter: {
          "title": "Google Chronicle destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Google Chronicle."
        }
      }, {
        name: "google-operations-suite",
        route: "/pipeline-data/plugins/destination-plugins/google-operations-suite",
        frontMatter: {
          "title": "Google Cloud Operations Suite destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Google Cloud Operations Suite."
        }
      }, {
        name: "grafana-loki",
        route: "/pipeline-data/plugins/destination-plugins/grafana-loki",
        frontMatter: {
          "title": "Grafana Loki destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Grafana Loki."
        }
      }, {
        name: "graylog",
        route: "/pipeline-data/plugins/destination-plugins/graylog",
        frontMatter: {
          "title": "Graylog destination plugin (Telemetry Pipeline)",
          "description": "Use the Graylog destination plugin."
        }
      }, {
        name: "http",
        route: "/pipeline-data/plugins/destination-plugins/http",
        frontMatter: {
          "title": "HTTP destination plugin (Telemetry Pipeline)",
          "description": "Use the HTTP destination plugin."
        }
      }, {
        name: "influxdb",
        route: "/pipeline-data/plugins/destination-plugins/influxdb",
        frontMatter: {
          "title": "InfluxDB destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to InfluxDB."
        }
      }, {
        name: "kafka",
        route: "/pipeline-data/plugins/destination-plugins/kafka",
        frontMatter: {
          "title": "Kafka destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Kafka."
        }
      }, {
        name: "new-relic",
        route: "/pipeline-data/plugins/destination-plugins/new-relic",
        frontMatter: {
          "title": "New Relic destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to New Relic."
        }
      }, {
        name: "null",
        route: "/pipeline-data/plugins/destination-plugins/null",
        frontMatter: {
          "title": "Null destination plugin (Telemetry Pipeline)",
          "description": "Use the null destination plugin."
        }
      }, {
        name: "observe",
        route: "/pipeline-data/plugins/destination-plugins/observe",
        frontMatter: {
          "title": "Observe destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Observe."
        }
      }, {
        name: "opensearch",
        route: "/pipeline-data/plugins/destination-plugins/opensearch",
        frontMatter: {
          "title": "OpenSearch destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to OpenSearch."
        }
      }, {
        name: "opentelemetry",
        route: "/pipeline-data/plugins/destination-plugins/opentelemetry",
        frontMatter: {
          "title": "OpenTelemetry destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to OpenTelemetry."
        }
      }, {
        name: "oracle",
        route: "/pipeline-data/plugins/destination-plugins/oracle",
        frontMatter: {
          "title": "Oracle Log Analytics destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Oracle Log Analytics."
        }
      }, {
        name: "prometheus-exporter",
        route: "/pipeline-data/plugins/destination-plugins/prometheus-exporter",
        frontMatter: {
          "title": "Prometheus Exporter destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data using Prometheus Exporter."
        }
      }, {
        name: "prometheus-remote-write",
        route: "/pipeline-data/plugins/destination-plugins/prometheus-remote-write",
        frontMatter: {
          "title": "Prometheus Remote Write destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Prometheus Remote Write."
        }
      }, {
        name: "redpanda",
        route: "/pipeline-data/plugins/destination-plugins/redpanda",
        frontMatter: {
          "title": "Redpanda Cloud destination plugin (Telemetry Pipeline)",
          "description": "Use the Redpanda Cloud destination plugin."
        }
      }, {
        name: "scalyr",
        route: "/pipeline-data/plugins/destination-plugins/scalyr",
        frontMatter: {
          "title": "Scalyr destination plugin (Telemetry Pipeline)",
          "description": "Use the Scalyr destination plugin."
        }
      }, {
        name: "slack",
        route: "/pipeline-data/plugins/destination-plugins/slack",
        frontMatter: {
          "title": "Slack destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Slack."
        }
      }, {
        name: "splunk",
        route: "/pipeline-data/plugins/destination-plugins/splunk",
        frontMatter: {
          "title": "Splunk destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Splunk."
        }
      }, {
        name: "standard-output",
        route: "/pipeline-data/plugins/destination-plugins/standard-output",
        frontMatter: {
          "title": "Standard output destination plugin (Telemetry Pipeline)",
          "description": "Use the Standard output destination plugin."
        }
      }, {
        name: "sumologic",
        route: "/pipeline-data/plugins/destination-plugins/sumologic",
        frontMatter: {
          "title": "Sumo Logic destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Sumo Logic."
        }
      }, {
        name: "syslog",
        route: "/pipeline-data/plugins/destination-plugins/syslog",
        frontMatter: {
          "title": "Syslog destination plugin (Telemetry Pipeline)",
          "description": "Use the syslog destination plugin."
        }
      }, {
        name: "tcp",
        route: "/pipeline-data/plugins/destination-plugins/tcp",
        frontMatter: {
          "title": "TCP destination plugin (Telemetry Pipeline)",
          "description": "Use the TCP destination plugin."
        }
      }, {
        name: "udp",
        route: "/pipeline-data/plugins/destination-plugins/udp",
        frontMatter: {
          "title": "UDP destination plugin (Telemetry Pipeline)",
          "description": "Use the UDP destination plugin."
        }
      }, {
        name: "victoriametrics",
        route: "/pipeline-data/plugins/destination-plugins/victoriametrics",
        frontMatter: {
          "title": "VictoriaMetrics destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to VictoriaMetrics."
        }
      }, {
        name: "vivo",
        route: "/pipeline-data/plugins/destination-plugins/vivo",
        frontMatter: {
          "title": "Vivo destination plugin (Telemetry Pipeline)",
          "description": "Send pipeline data to Vivo."
        }
      }]
    }, {
      name: "destination-plugins",
      route: "/pipeline-data/plugins/destination-plugins",
      frontMatter: {
        "title": "Send telemetry data with destination plugins (Telemetry Pipeline)",
        "description": "Send telemetry data to various destinations."
      }
    }, {
      name: "source-plugins",
      route: "/pipeline-data/plugins/source-plugins",
      children: [{
        data: pipeline_data_plugins_source_plugins_meta
      }, {
        name: "absolute",
        route: "/pipeline-data/plugins/source-plugins/absolute",
        frontMatter: {
          "title": "Absolute source plugin (Telemetry Pipeline)",
          "description": "Use the Absolute source plugin."
        }
      }, {
        name: "amazon-kinesis-firehose",
        route: "/pipeline-data/plugins/source-plugins/amazon-kinesis-firehose",
        frontMatter: {
          "title": "Amazon Kinesis Firehose source plugin (Telemetry Pipeline)",
          "description": "Receive pipeline data from Amazon Kinesis Firehose."
        }
      }, {
        name: "amazon-kinesis-stream",
        route: "/pipeline-data/plugins/source-plugins/amazon-kinesis-stream",
        frontMatter: {
          "title": "Amazon Kinesis Stream Input source plugin (Telemetry Pipeline)",
          "description": "Receive pipeline data from Amazon Kinesis Stream Input."
        }
      }, {
        name: "amazon-s3",
        route: "/pipeline-data/plugins/source-plugins/amazon-s3",
        frontMatter: {
          "title": "S3 Input (One Time) source plugin (Telemetry Pipeline)",
          "description": "Receive pipeline data from Amazon S3."
        }
      }, {
        name: "azure-event-grid",
        route: "/pipeline-data/plugins/source-plugins/azure-event-grid",
        frontMatter: {
          "title": "Azure Event Grid source plugin (Telemetry Pipeline)",
          "description": "Receive pipeline data from Azure Event Grid."
        }
      }, {
        name: "azure-event-hub",
        route: "/pipeline-data/plugins/source-plugins/azure-event-hub",
        frontMatter: {
          "title": "Azure Event Hub source plugin (Telemetry Pipeline)",
          "description": "Receive pipeline data from Azure Event Hub."
        }
      }, {
        name: "bash",
        route: "/pipeline-data/plugins/source-plugins/bash",
        frontMatter: {
          "title": "Bash Command source plugin (Telemetry Pipeline)",
          "description": "Run a bash command in Chronosphere Telemetry Pipeline."
        }
      }, {
        name: "cloudflare",
        route: "/pipeline-data/plugins/source-plugins/cloudflare",
        frontMatter: {
          "title": "Cloudflare source plugin (Telemetry Pipeline)",
          "description": "Receive pipeline data from Cloudflare."
        }
      }, {
        name: "confluent-cloud",
        route: "/pipeline-data/plugins/source-plugins/confluent-cloud",
        frontMatter: {
          "title": "Confluent Cloud source plugin (Telemetry Pipeline)",
          "description": "Use the Confluent Cloud source plugin."
        }
      }, {
        name: "datadog-agent",
        route: "/pipeline-data/plugins/source-plugins/datadog-agent",
        frontMatter: {
          "title": "Datadog Agent source plugin (Telemetry Pipeline)",
          "description": "Use the Datadog Agent source plugin to ingest log data."
        }
      }, {
        name: "datagen",
        route: "/pipeline-data/plugins/source-plugins/datagen",
        frontMatter: {
          "title": "Datagen source plugin (Telemetry Pipeline)",
          "description": "Use the Datagen source plugin to generate simulated log data to test your pipeline configuration."
        }
      }, {
        name: "dummy-http",
        route: "/pipeline-data/plugins/source-plugins/dummy-http",
        frontMatter: {
          "title": "Dummy HTTP source plugin (Telemetry Pipeline)",
          "description": "Generate sample telemetry data for Chronosphere Telemetry Pipeline."
        }
      }, {
        name: "dummy",
        route: "/pipeline-data/plugins/source-plugins/dummy",
        frontMatter: {
          "title": "Dummy logs, metrics, and traces source plugin (Telemetry Pipeline)",
          "description": "Generate sample telemetry data for Chronosphere Telemetry Pipeline."
        }
      }, {
        name: "elasticsearch-http",
        route: "/pipeline-data/plugins/source-plugins/elasticsearch-http",
        frontMatter: {
          "title": "Elasticsearch HTTP source plugin (Telemetry Pipeline)",
          "description": "Receive pipeline data from Elasticsearch HTTP."
        }
      }, {
        name: "fluent-bit",
        route: "/pipeline-data/plugins/source-plugins/fluent-bit",
        frontMatter: {
          "title": "Fluent Bit source plugin (Telemetry Pipeline)",
          "description": "Gather telemetry from Fluent Bit sources to send to Chronosphere Telemetry Pipeline for processing and routing."
        }
      }, {
        name: "fluentd",
        route: "/pipeline-data/plugins/source-plugins/fluentd",
        frontMatter: {
          "title": "Fluentd source plugin (Telemetry Pipeline)",
          "description": "Use the Fluentd source plugin."
        }
      }, {
        name: "forward",
        route: "/pipeline-data/plugins/source-plugins/forward",
        frontMatter: {
          "title": "Forward source plugin (Telemetry Pipeline)",
          "description": "Use the forward source plugin."
        }
      }, {
        name: "google-cloud-pubsub",
        route: "/pipeline-data/plugins/source-plugins/google-cloud-pubsub",
        frontMatter: {
          "title": "Google Cloud PubSub Input source plugin (Telemetry Pipeline)",
          "description": "Receive pipeline data from Google Cloud PubSub."
        }
      }, {
        name: "google-workspace-audit",
        route: "/pipeline-data/plugins/source-plugins/google-workspace-audit",
        frontMatter: {
          "title": "Google Workspace Audit Logs source plugin (Telemetry Pipeline)",
          "description": "Use the Google Workspace Audit Logs source plugin."
        }
      }, {
        name: "http-api-collector",
        route: "/pipeline-data/plugins/source-plugins/http-api-collector",
        frontMatter: {
          "title": "HTTP API collector source plugin (Telemetry Pipeline)",
          "description": "Receive pipeline data from the HTTP API collector."
        }
      }, {
        name: "http",
        route: "/pipeline-data/plugins/source-plugins/http",
        frontMatter: {
          "title": "HTTP source plugin (Telemetry Pipeline)",
          "description": "Use the HTTP source plugin."
        }
      }, {
        name: "intune",
        route: "/pipeline-data/plugins/source-plugins/intune",
        frontMatter: {
          "title": "Microsoft Intune - Audit event source plugin (Telemetry Pipeline)",
          "description": "Ingest Microsoft Intune - Audit events in Chronosphere Telemetry Pipeline."
        }
      }, {
        name: "kafka",
        route: "/pipeline-data/plugins/source-plugins/kafka",
        frontMatter: {
          "title": "Kafka source plugin (Telemetry Pipeline)",
          "description": "Receive pipeline data from Kafka."
        }
      }, {
        name: "kubernetes",
        route: "/pipeline-data/plugins/source-plugins/kubernetes",
        frontMatter: {
          "title": "Kubernetes Events source plugin (Telemetry Pipeline)",
          "description": "Ingest Kubernetes events in Chronosphere Telemetry Pipeline."
        }
      }, {
        name: "mandiant-asm",
        route: "/pipeline-data/plugins/source-plugins/mandiant-asm",
        frontMatter: {
          "title": "Mandiant ASM source plugin (Telemetry Pipeline)",
          "description": "Use the Mandiant ASM source plugin."
        }
      }, {
        name: "mock-data",
        route: "/pipeline-data/plugins/source-plugins/mock-data",
        frontMatter: {
          "title": "Mock Data source plugin (Telemetry Pipeline)",
          "description": "Use the Mock Data source plugin."
        }
      }, {
        name: "okta",
        route: "/pipeline-data/plugins/source-plugins/okta",
        frontMatter: {
          "title": "Okta System Logs Collector source plugin (Telemetry Pipeline)",
          "description": "Receive pipeline data from Okta."
        }
      }, {
        name: "opentelemetry",
        route: "/pipeline-data/plugins/source-plugins/opentelemetry",
        frontMatter: {
          "title": "OpenTelemetry source plugin (Telemetry Pipeline)",
          "description": "Receive pipeline data from OpenTelemetry."
        }
      }, {
        name: "prometheus-remote",
        route: "/pipeline-data/plugins/source-plugins/prometheus-remote",
        frontMatter: {
          "title": "Prometheus Remote Write source plugin (Telemetry Pipeline)",
          "description": "Use the Prometheus Remote Write source plugin."
        }
      }, {
        name: "prometheus-scrape",
        route: "/pipeline-data/plugins/source-plugins/prometheus-scrape",
        frontMatter: {
          "title": "Prometheus Scrape source plugin (Telemetry Pipeline)",
          "description": "Use the Prometheus Scrape source plugin."
        }
      }, {
        name: "s3-sqs",
        route: "/pipeline-data/plugins/source-plugins/s3-sqs",
        frontMatter: {
          "title": "S3 Input (SQS) source plugin (Telemetry Pipeline)",
          "description": "Use the S3 Input (SQS) source plugin."
        }
      }, {
        name: "signal-sciences",
        route: "/pipeline-data/plugins/source-plugins/signal-sciences",
        frontMatter: {
          "title": "Signal Sciences source plugin (Telemetry Pipeline)",
          "description": "Use the Signal Sciences source plugin."
        }
      }, {
        name: "slack",
        route: "/pipeline-data/plugins/source-plugins/slack",
        frontMatter: {
          "title": "Slack source plugin (Telemetry Pipeline)",
          "description": "Use the Slack source plugin."
        }
      }, {
        name: "splunk-hec",
        route: "/pipeline-data/plugins/source-plugins/splunk-hec",
        frontMatter: {
          "title": "Splunk HEC source plugin (Telemetry Pipeline)",
          "description": "Use the Splunk HEC scrape source plugin."
        }
      }, {
        name: "splunk-uf",
        route: "/pipeline-data/plugins/source-plugins/splunk-uf",
        frontMatter: {
          "title": "Splunk UF source plugin (Telemetry Pipeline)",
          "description": "Receive pipeline data from the Splunk Universal Forwarder."
        }
      }, {
        name: "sqldb",
        route: "/pipeline-data/plugins/source-plugins/sqldb",
        frontMatter: {
          "title": "SQL DB Input source plugin (Telemetry Pipeline)",
          "description": "Use the SQL DB Input source plugin with Chronosphere Telemetry Pipeline."
        }
      }, {
        name: "syslog",
        route: "/pipeline-data/plugins/source-plugins/syslog",
        frontMatter: {
          "title": "Syslog source plugin (Telemetry Pipeline)",
          "description": "Use the Syslog source plugin."
        }
      }, {
        name: "tcp",
        route: "/pipeline-data/plugins/source-plugins/tcp",
        frontMatter: {
          "title": "TCP source plugin (Telemetry Pipeline)",
          "description": "Use the TCP source plugin."
        }
      }, {
        name: "telegraf",
        route: "/pipeline-data/plugins/source-plugins/telegraf",
        frontMatter: {
          "title": "Telegraf source plugin (Telemetry Pipeline)",
          "description": "Receive pipeline data from Telegraf."
        }
      }, {
        name: "vectram365",
        route: "/pipeline-data/plugins/source-plugins/vectram365",
        frontMatter: {
          "title": "Vectra M365 - Incident Detection source plugin (Telemetry Pipeline)",
          "description": "Use the Vectra M365 - Incident Detection source plugin."
        }
      }, {
        name: "vercel",
        route: "/pipeline-data/plugins/source-plugins/vercel",
        frontMatter: {
          "title": "Vercel Logs source plugin (Telemetry Pipeline)",
          "description": "Receive pipeline data from Vercel."
        }
      }]
    }, {
      name: "source-plugins",
      route: "/pipeline-data/plugins/source-plugins",
      frontMatter: {
        "title": "Ingest telemetry data with source plugins (Telemetry Pipeline)",
        "description": "Ingest input data from various sources."
      }
    }, {
      name: "test-settings",
      route: "/pipeline-data/plugins/test-settings",
      frontMatter: {
        "title": "Test plugin settings",
        "description": "Learn how to test plugin settings and ensure data is flowing through your pipeline."
      }
    }]
  }, {
    name: "plugins",
    route: "/pipeline-data/plugins",
    frontMatter: {
      "title": "Telemetry Pipeline plugins",
      "description": "Learn how to use plugins in Chronosphere Telemetry Pipeline."
    }
  }, {
    name: "processing-rules",
    route: "/pipeline-data/processing-rules",
    children: [{
      data: pipeline_data_processing_rules_meta
    }, {
      name: "add-set-key-value",
      route: "/pipeline-data/processing-rules/add-set-key-value",
      frontMatter: {
        "title": "Add set key/value processing rule",
        "description": "Learn about the add/set key/value processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "aggregate-records",
      route: "/pipeline-data/processing-rules/aggregate-records",
      frontMatter: {
        "title": "Aggregate records",
        "description": "Learn about the aggregate records processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "allow-keys",
      route: "/pipeline-data/processing-rules/allow-keys",
      frontMatter: {
        "title": "Allow keys processing rule",
        "description": "Learn about the allow keys processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "allow-records",
      route: "/pipeline-data/processing-rules/allow-records",
      frontMatter: {
        "title": "Allow records",
        "description": "Learn about the allow records processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "block-keys",
      route: "/pipeline-data/processing-rules/block-keys",
      frontMatter: {
        "title": "Block keys",
        "description": "Learn about the block keys processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "block-records",
      route: "/pipeline-data/processing-rules/block-records",
      frontMatter: {
        "title": "Block records",
        "description": "Learn about the block records processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "copy-keys",
      route: "/pipeline-data/processing-rules/copy-keys",
      frontMatter: {
        "title": "Copy keys",
        "description": "Learn about the copy keys processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "custom-lua",
      route: "/pipeline-data/processing-rules/custom-lua",
      frontMatter: {
        "title": "Custom Lua",
        "description": "Learn about the custom Lua processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "decode-csv",
      route: "/pipeline-data/processing-rules/decode-csv",
      frontMatter: {
        "title": "Decode CSV",
        "description": "Learn about the decode CSV processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "decode-json",
      route: "/pipeline-data/processing-rules/decode-json",
      frontMatter: {
        "title": "Decode JSON",
        "description": "Learn about the decode JSON processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "deduplicate-records",
      route: "/pipeline-data/processing-rules/deduplicate-records",
      frontMatter: {
        "title": "Deduplicate records",
        "description": "Learn about the deduplicate records processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "delete-key",
      route: "/pipeline-data/processing-rules/delete-key",
      frontMatter: {
        "title": "Delete key",
        "description": "Learn about the delete key processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "encode-csv",
      route: "/pipeline-data/processing-rules/encode-csv",
      frontMatter: {
        "title": "Encode CSV",
        "description": "Learn about the encode CSV processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "encode-json",
      route: "/pipeline-data/processing-rules/encode-json",
      frontMatter: {
        "title": "Encode JSON",
        "description": "Learn about the encode JSON processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "extract-keys-values",
      route: "/pipeline-data/processing-rules/extract-keys-values",
      frontMatter: {
        "title": "Extract keys/values",
        "description": "Learn about the extract keys/values processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "flatten-subrecord",
      route: "/pipeline-data/processing-rules/flatten-subrecord",
      frontMatter: {
        "title": "Flatten subrecord",
        "description": "Learn about the flatten subrecord processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "hash-key",
      route: "/pipeline-data/processing-rules/hash-key",
      frontMatter: {
        "title": "Hash key",
        "description": "Learn about the hash key processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "join-records",
      route: "/pipeline-data/processing-rules/join-records",
      frontMatter: {
        "title": "Join records",
        "description": "Learn about the join records processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "lift-submap",
      route: "/pipeline-data/processing-rules/lift-submap",
      frontMatter: {
        "title": "Lift submap",
        "description": "Learn about the lift submap processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "multiline-join",
      route: "/pipeline-data/processing-rules/multiline-join",
      frontMatter: {
        "title": "Multiline join",
        "description": "Learn about the multiline join processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "nest-keys",
      route: "/pipeline-data/processing-rules/nest-keys",
      frontMatter: {
        "title": "Nest keys",
        "description": "Learn about the nest keys processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "parse-number",
      route: "/pipeline-data/processing-rules/parse-number",
      frontMatter: {
        "title": "Parse number",
        "description": "Learn about the parse number processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "parse",
      route: "/pipeline-data/processing-rules/parse",
      frontMatter: {
        "title": "Parse",
        "description": "Learn about the parse processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "random-sampling",
      route: "/pipeline-data/processing-rules/random-sampling",
      frontMatter: {
        "title": "Random sampling",
        "description": "Learn about the random sampling processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "redact-mask-value",
      route: "/pipeline-data/processing-rules/redact-mask-value",
      frontMatter: {
        "title": "Redact/mask value",
        "description": "Learn about the redact/mask value processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "rename-keys",
      route: "/pipeline-data/processing-rules/rename-keys",
      frontMatter: {
        "title": "Rename keys",
        "description": "Learn about the rename keys processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "search-replace-value",
      route: "/pipeline-data/processing-rules/search-replace-value",
      frontMatter: {
        "title": "Search/replace value",
        "description": "Learn about the search/replace value processing rule in Chronosphere Telemetry Pipeline."
      }
    }, {
      name: "split-record",
      route: "/pipeline-data/processing-rules/split-record",
      frontMatter: {
        "title": "Split record",
        "description": "Learn about the split record processing rule in Chronosphere Telemetry Pipeline."
      }
    }]
  }, {
    name: "processing-rules",
    route: "/pipeline-data/processing-rules",
    frontMatter: {
      "title": "Processing rules",
      "description": "Transform data as it passes through your telemetry pipeline."
    }
  }, {
    name: "troubleshoot",
    route: "/pipeline-data/troubleshoot",
    frontMatter: {
      "title": "Troubleshooting pipelines",
      "description": "Learn how to troubleshoot pipelines in Chronosphere Telemetry Pipeline."
    }
  }]
}, {
  name: "pipeline-data",
  route: "/pipeline-data",
  frontMatter: {
    "title": "Manage telemetry data",
    "description": "Use Chronosphere Telemetry Pipeline to manage telemetry data."
  }
}, {
  name: "pipeline-install",
  route: "/pipeline-install",
  children: [{
    data: pipeline_install_meta
  }, {
    name: "create-instance",
    route: "/pipeline-install/create-instance",
    frontMatter: {
      "title": "Create a Core Instance",
      "description": "Learn how to create a Core Instance in Chronosphere Telemetry Pipeline."
    }
  }, {
    name: "environment-variables",
    route: "/pipeline-install/environment-variables",
    frontMatter: {
      "title": "Linux environment variables",
      "description": "Configure environment variables to use Chronosphere Telemetry Pipleine on Linux."
    }
  }, {
    name: "install-operator",
    route: "/pipeline-install/install-operator",
    children: [{
      data: pipeline_install_install_operator_meta
    }, {
      name: "kubernetes-cluster",
      route: "/pipeline-install/install-operator/kubernetes-cluster",
      frontMatter: {
        "title": "Install a Core Operator in a Kubernetes cluster",
        "description": "Install a Core Operator to run Telemetry Pipeline in a Kubernetes cluster."
      }
    }, {
      name: "linux",
      route: "/pipeline-install/install-operator/linux",
      frontMatter: {
        "title": "Install a Core Operator on Linux",
        "description": "Install a Core Operator to run Telemetry Pipeline on Linux."
      }
    }]
  }, {
    name: "install-operator",
    route: "/pipeline-install/install-operator",
    frontMatter: {
      "title": "Install a Core Operator",
      "description": "Install a Core Operator to run Telemetry Pipeline in Kubernetes or Linux."
    }
  }, {
    name: "prereqs",
    route: "/pipeline-install/prereqs",
    frontMatter: {
      "title": "Installation prerequisites for Telemetry Pipeline",
      "description": "Learn about the prerequisites to install Chronosphere Telemetry Pipeline."
    }
  }, {
    name: "troubleshooting",
    route: "/pipeline-install/troubleshooting",
    frontMatter: {
      "title": "Troubleshoot Telemetry Pipeline Linux installations",
      "description": "Steps to troubleshoot Chronosphere Telemetry Pipeline Linux installations"
    }
  }, {
    name: "uninstall",
    route: "/pipeline-install/uninstall",
    frontMatter: {
      "title": "Uninstall Telemetry Pipeline",
      "description": "Learn how to uninstall Chronosphere Telemetry Pipeline."
    }
  }, {
    name: "upgrade",
    route: "/pipeline-install/upgrade",
    frontMatter: {
      "title": "Upgrade Telemetry Pipeline",
      "description": "Learn how to upgrade various components of Chronosphere Telemetry Pipeline."
    }
  }]
}, {
  name: "pipeline-install",
  route: "/pipeline-install",
  frontMatter: {
    "title": "Install Chronosphere Telemetry Pipeline",
    "description": "Deploy Chronosphere Telemetry Pipeline on the platform of your choice."
  }
}, {
  name: "pipeline-navigate",
  route: "/pipeline-navigate",
  frontMatter: {
    "title": "Navigate the Telemetry Pipeline web interface",
    "description": "Learn how to navigate the Chronosphere Telemetry Pipeline web interface."
  }
}, {
  name: "pipelines",
  route: "/pipelines",
  children: [{
    data: pipelines_meta
  }, {
    name: "architecture",
    route: "/pipelines/architecture",
    frontMatter: {
      "title": "Chronosphere Telemetry Pipeline architecture"
    }
  }, {
    name: "concepts",
    route: "/pipelines/concepts",
    frontMatter: {
      "title": "Telemetry Pipeline concepts",
      "description": "Learn more about different components, concepts, and terms in Chronosphere Telemetry Pipeline."
    }
  }, {
    name: "core-components",
    route: "/pipelines/core-components",
    frontMatter: {
      "title": "Telemetry Pipeline component versions",
      "description": "Reference the version numbers for individual Chronosphere Telemetry Pipeline components."
    }
  }, {
    name: "release-notes",
    route: "/pipelines/release-notes",
    frontMatter: {
      "title": "Chronosphere Telemetry Pipeline release notes",
      "description": "Information about releases of Chronosphere Telemetry Pipeline and its components."
    }
  }, {
    name: "security",
    route: "/pipelines/security",
    frontMatter: {
      "title": "Supply chain security",
      "description": "Reference the Chronosphere Telemetry Pipeline public keys."
    }
  }]
}, {
  name: "pipelines",
  route: "/pipelines",
  frontMatter: {
    "title": "Chronosphere Telemetry Pipeline",
    "description": "Learn about Chronosphere Telemetry Pipeline."
  }
}, {
  name: "status",
  route: "/status",
  frontMatter: {
    "title": "View Chronosphere app service status",
    "description": "Find out how the determine the status of your Chronosphere apps."
  }
}, {
  name: "support",
  route: "/support",
  frontMatter: {
    "title": "Contact Chronosphere Support",
    "description": "Chronosphere Support offers comprehensive resources including documentation, troubleshooting guides, and direct assistance for resolving observability issues."
  }
}, {
  name: "tooling",
  route: "/tooling",
  children: [{
    data: tooling_meta
  }, {
    name: "api-info",
    route: "/tooling/api-info",
    children: [{
      data: tooling_api_info_meta
    }, {
      name: "dashboard_schema",
      route: "/tooling/api-info/dashboard_schema",
      children: []
    }, {
      name: "data_definition",
      route: "/tooling/api-info/data_definition",
      children: []
    }, {
      name: "definition",
      route: "/tooling/api-info/definition",
      children: []
    }, {
      name: "pagination",
      route: "/tooling/api-info/pagination",
      frontMatter: {
        "title": "Pagination"
      }
    }, {
      name: "state_definition",
      route: "/tooling/api-info/state_definition",
      children: []
    }, {
      name: "unstable_config_definition",
      route: "/tooling/api-info/unstable_config_definition",
      children: []
    }, {
      name: "unstable_data_definition",
      route: "/tooling/api-info/unstable_data_definition",
      children: []
    }, {
      name: "unstable_state_definition",
      route: "/tooling/api-info/unstable_state_definition",
      children: []
    }]
  }, {
    name: "api-info",
    route: "/tooling/api-info",
    frontMatter: {
      "title": "Get started with the Chronosphere API",
      "description": "How to access and use the Chronosphere API for programmatic access to your observability data."
    }
  }, {
    name: "chronoctl",
    route: "/tooling/chronoctl",
    children: [{
      data: tooling_chronoctl_meta
    }, {
      name: "install",
      route: "/tooling/chronoctl/install",
      frontMatter: {
        "title": "Install Chronoctl",
        "description": "How to install the Chronoctl command line app to manage your Chronosphere Observability Platform instances."
      }
    }]
  }, {
    name: "chronoctl",
    route: "/tooling/chronoctl",
    frontMatter: {
      "title": "Chronoctl",
      "description": "Use Chronoctl to manage your organization's Chronosphere Observability Platform resources."
    }
  }, {
    name: "infrastructure",
    route: "/tooling/infrastructure",
    children: [{
      data: tooling_infrastructure_meta
    }, {
      name: "pulumi",
      route: "/tooling/infrastructure/pulumi",
      frontMatter: {
        "title": "Chronosphere Pulumi provider",
        "description": "Use the Chronosphere Pulumi provider to create and manage Chronosphere Observability Platform resources."
      }
    }, {
      name: "terraform",
      route: "/tooling/infrastructure/terraform",
      children: [{
        data: tooling_infrastructure_terraform_meta
      }, {
        name: "install",
        route: "/tooling/infrastructure/terraform/install",
        frontMatter: {
          "title": "Install the Chronosphere Terraform provider"
        }
      }, {
        name: "release-notes",
        route: "/tooling/infrastructure/terraform/release-notes",
        frontMatter: {
          "title": "Terraform provider release notes"
        }
      }]
    }, {
      name: "terraform",
      route: "/tooling/infrastructure/terraform",
      frontMatter: {
        "title": "Chronosphere Terraform provider"
      }
    }]
  }, {
    name: "infrastructure",
    route: "/tooling/infrastructure",
    frontMatter: {
      "title": "Infrastructure-as-code tools"
    }
  }, {
    name: "prometheus-api",
    route: "/tooling/prometheus-api",
    frontMatter: {
      "title": "Prometheus API overview"
    }
  }]
}, {
  name: "tooling-overview",
  route: "/tooling-overview",
  frontMatter: {
    "title": "Tools",
    "description": "Learn about tools used with Chronosphere products."
  }
}, {
  name: "tooling",
  route: "/tooling",
  frontMatter: {
    "title": "Observability Platform Tools",
    "description": "Learn about tools used with Observability Platform."
  }
}];