const meta = {
  'amazon-cloudwatch': 'Amazon CloudWatch',
  'amazon-kinesis-firehose': 'Amazon Kinesis Firehose',
  'amazon-kinesis-streams': 'Amazon Kinesis Streams',
  'amazon-s3': 'Amazon S3',
  axiom: 'Axiom',
  'azure-blob-storage': 'Azure Blob Storage',
  'azure-data-explorer': 'Azure Data Explorer',
  'azure-eventhub': 'Azure EventHub',
  'azure-ingestion-api': 'Azure Logs Ingestion API',
  'azure-monitor': 'Azure Monitor',
  'azure-sentinel': 'Azure Sentinel',
  chronosphere: 'Chronosphere',
  clickhouse: 'Clickhouse',
  'confluent-cloud': 'Confluent Cloud',
  coralogix: 'Coralogix',
  crowdstrike: 'Crowdstrike',
  datadog: 'Datadog',
  devo: 'Devo Cloud',
  dynatrace: 'Dynatrace',
  elasticsearch: 'Elasticsearch',
  exabeam: 'Exabeam',
  forward: 'Forward',
  'google-chronicle': 'Google Chronicle',
  'google-bigquery': 'Google Cloud Big Query',
  'google-operations-suite': 'Google Cloud Operations Suite',
  'grafana-loki': 'Grafana Loki',
  graylog: 'Graylog',
  http: 'HTTP',
  influxdb: 'InfluxDB',
  kafka: 'Kafka',
  'new-relic': 'New Relic',
  null: 'Null',
  observe: 'Observe',
  oracle: 'Oracle',
  opensearch: 'OpenSearch',
  opentelemetry: 'OpenTelemetry',
  'prometheus-exporter': 'Prometheus Exporter',
  'prometheus-remote-write': 'Prometheus Remote Write',
  redpanda: 'Redpanda Cloud',
  scalyr: 'Scalyr',
  slack: 'Slack',
  splunk: 'Splunk',
  'standard-output': 'Standard output',
  sumologic: 'Sumo Logic',
  syslog: 'Syslog',
  tcp: 'TCP',
  udp: 'UDP',
  victoriametrics: 'VictoriaMetrics',
  vivo: 'Vivo',
};

export default meta;
