const meta = {
  explorer: 'Metrics Explorer',
  builder: 'Query Builder',
  'query-analyzer': 'Query Analyzer',
  promql: 'Using PromQL',
  'delta-queries': 'Using delta queries',
  fixing: 'Fixing slow queries',
  'derived-labels': 'Derived labels',
  'derived-metrics': 'Derived metrics',
  'explorer-classic': 'Metrics Explorer (classic)',
};

export default meta;
