const meta = {
  querying: 'Query data',
  'change-events': 'Change events',
  traces: 'Traces',
  logs: 'Logs',
  metrics: 'Metrics',
  'differential-diagnosis': 'Differential diagnosis',
  'logs-oem': {
    display: 'hidden',
  },
};

export default meta;
