const meta = {
  'deployment-strategies': 'Deployment strategies',
  files: 'Files',
  helm: 'Helm configuration',
  'history-rollback': 'History and rollback',
  'kubernetes-deployment': 'Kubernetes deployment settings',
  metadata: 'Metadata',
  'config-files': 'Pipeline configuration files',
  sidecars: 'Pipeline sidecars',
  ports: 'Ports',
  'resource-labels': 'Resource labels',
  'resource-profiles': 'Resource profiles',
  scaling: 'Scaling and replicas',
  secrets: 'Secrets',
  'service-types': 'Service types',
  'http-proxy': 'Use an HTTP proxy',
};

export default meta;
