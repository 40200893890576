const meta = {
  dogstatsd: 'Querying',
  'migration-issues': 'Migration considerations',
  'convert-monitors': 'Monitor migration',
  dashboards: 'Recreate widgets',
  mapping: {
    display: 'hidden',
  },
};

export default meta;
