const meta = {
  'add-set-key-value': 'Add/set key/value',
  'aggregate-records': 'Aggregate records',
  'allow-keys': 'Allow keys',
  'allow-records': 'Allow records',
  'block-keys': 'Block keys',
  'block-records': 'Block records',
  'copy-keys': 'Copy keys',
  'custom-lua': 'Custom Lua',
  'decode-csv': 'Decode CSV',
  'decode-json': 'Decode JSON',
  'deduplicate-records': 'Deduplicate records',
  'delete-key': 'Delete key',
  'encode-csv': 'Encode CSV',
  'encode-json': 'Encode JSON',
  'extract-keys-values': 'Extract keys/values',
  'flatten-subrecord': 'Flatten subrecord',
  'hash-key': 'Hash key',
  'join-records': 'Join records',
  'lift-submap': 'Lift submap',
  'multiline-join': 'Multiline join',
  'nest-keys': 'Nest keys',
  parse: 'Parse',
  'parse-number': 'Parse number',
  'random-sampling': 'Random sampling',
  'redact-mask-value': 'Redact/mask value',
  'rename-keys': 'Rename keys',
  'search-replace-value': 'Search/replace value',
  'split-record': 'Split record',
};

export default meta;
